/* Module: S */
.module__famb-search-main {
    .famb__search {
        padding: 40px 20px 20px 40px;
    }
    .famb__search--blocks {
        display: flex;
        .famb__search--block {
            flex: 1 0 33%;
            margin-right: 0 !important;
            padding: 0 40px 0 0;
        }
        .famb-max-distance {
            flex: 18%;
            padding: 0 10px 0 0;
        }
    }
    
}
.famb-item__recognition {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    width: 80px;
}
.famb-item__award {
    padding-right: 0;
}
.famb-search__listing .famb-item__title {
    padding: 0 100px 0 0;
}