/* Module: S */
body {
	z-index: 3;

	&.mobile-menu-active {
		overflow: auto;
	}
}
body .hidden-tablet {
  display: inline-block !important;
	visibility: visible;
}
body .hidden-desktop {
  display: none !important;
  visibility: hidden;
}


.max-width-desktop {
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
	// position: relative;
}

.module__famb-search .max-width-desktop {
	position: relative;
}

.relative, #main {
	position: relative;
}
.row__global {
	// @media only screen and (min-width:1140px) {
		display: block;
		width: 100%;
		clear: both;
		position: relative;

		.row__inner {
			display: block;
			max-width: pxToRem(1140);
			margin: 0 auto;
			padding: 0;
		}
	// }
}
.header .info_bar + .row__global > .row__inner {
	@media only screen and (min-width:1200px) {
		@include sq-flex-box();
		flex-wrap: nowrap;
	}
}
// .navigation ul {
// 	@media only screen and (min-width:1140px) {
// 		@include sq-flex-box();
// 	}
// }
.site-logo {
	display: block;
	float: left;
	// padding: 0 20px 0 5px;

	a {
		width: auto;
		min-width: 150px;
		padding-right: pxToRem(16);
		// padding: pxToRem(13) 0 pxToRem(25) 0;
	}
}
.navigation {
	@media only screen and (min-width:1200px) {
		position: static;
		z-index: auto;
		left: 0;
		background: none;
		display: block;
	  // float: right;
		width: auto;
		margin: 0 24px 0 0;
	}

	&.active {
		height: fit-content;
		position: fixed;
	}

	&__link {
		&:first-child:not(:only-child) {
			// padding-left: 20px;
		}
	}

	a.navigation__toggler {
		@media only screen and (min-width:1200px) {
			// position: absolute;
			// top: 39px;
			// right: -10px;
			padding: 0;
			color: $base-orange;
			display: block;
			font-size: 1.25rem;

			.fa {
				display: block;
			}
		}
	}
}
.navigation__list-item {
	@media only screen and (min-width:1200px) {
		display: flex;
    	align-items: center;
		// position: relative;
		> a {
			color: $base-blue;
			padding: 42px 8px 45px 12px;
			white-space: nowrap;
		}
	}
}

@media only screen and (max-width:1080px) {
	.row__inner .navigation__list-item > a {
		// padding: 42px 8px 45px;
	}
	.row__inner .site-logo {
		// padding: 0 10px 0 10px;
	}
}	

a.navigation__link {
	position: relative;

	@media only screen and (min-width:1200px) {
		display: inline-block;
		padding-top: 0;
		padding-bottom: 0;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50px;
		left: 50%;
		margin: 0 0 0 -15px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 14px 14px 14px;
		border-color: transparent transparent #1d3273 transparent;
		opacity: 0;
		transition: .25s opacity ease-in-out;
	}

	&:hover, 
	&:focus {
		color: $base-orange;

		&:after {
			// opacity: 1;
		}
	}
}

.active .navigation__link {
	&:after {
		@media only screen and (min-width:1200px) {
			opacity: 1;
		}
	}
}

.header {
	@media only screen and (min-width:1200px) {
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
		position: relative;
		z-index: auto;
	}

	.row__global {
		.row__inner {
			max-width: pxToRem(1400);
		}
	}
}
.header__extras {
	// top: 30px;
	// position: absolute;
	z-index: auto;	
}
.navigation__list-item {
	.mega-menu {
		@include sq-flex-box();
		position: absolute;
		background: $secondary-blue;
		padding: 0 20px;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		transition: .25s opacity ease-in-out, .25s visibility ease-in-out;
		top: 100%;
		z-index: 3;

	  &.active {
		  opacity: 1;
		  visibility: visible;
	  }

	  .mega-menu__list {
	  	flex-grow: 1;
	  	padding: 20px 0;
	  	flex-direction: column;
	  }
	}
}
.navigation__list-item.navigation__has-megamenu:nth-last-child(1) .mega-menu__list{
	width: 12em;
}
.navigation__list-item-last .mega-menu {
	// right: 0;
}

.column-count-1 {
	-moz-column-count: 1;
	-webkit-column-count: 1;
	column-count: 1;
}

.column-count-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
}

.column-count-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
}

/*
 *
 * Global Footer styling
 *
*/
.footer {
	// padding: pxToRem(40);

	.row__inner {
		position: relative;
	}
	.mb__chat {
		bottom: pxToRem(-25);
	}
}


/* 
 * 
 * MODULE: Page banner
 *
 */
.module__page-banner.page-banner-has-image {
	height: 400px;
}
.page-banner__inner {
display: block;
  max-width: 71.25rem;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: auto;
}
.page-banner__details {
	right: auto;
	left: 0; 
	top: auto;
	bottom: 20px;
	padding-bottom: pxToRem(30);

	&--new {
		right: auto;
		left: auto; 
		top: auto;
		bottom: auto;
		justify-content: flex-end;
		padding: pxToRem(30) pxToRem(40);
	}
}
.page-banner__item {
    height: 421px;
}
.page-banner-has-image {
	.page-banner__details {	
		bottom: 0;
	}

	.page-banner__details--new {	
		h1 {
			font-size: pxToRem(32);
		}
	}
}
.page-banner__title {
	font-size: pxToRem(40);
	margin: 0;
}
.page-banner__description {
	display: block;
	// color: $base-blue;
	font-size: pxToRem(20);
}
.page-banner-has-image .page-banner__description {
	// font-size: pxToRem(18);
	// color: #000;
}

.page-banner__items--fix {
    width: calc(100% - 400px);
}
.module__page-banner {
	.slick-dots {
		top: auto;
		right: auto;
		bottom: 0.75rem;
    }

	.slick-prev {
		left: 25px;
		right: auto;
	}

	.slick-next {
		right: 25px;
		left: auto;
	}
}

@media only screen and (max-width:1250px) {
	.module__page-banner .slick-dots {
		bottom: 1.75rem;
		left: 0.5rem;
	}
}

.page-banner-dots {
  position: relative;
  width: 100%;
  max-width: 71.25rem;
  margin: 0 auto;
  height: auto;
  left: pxToRem(20);
  bottom: pxToRem(20);
}




/* 
 * 
 * MODULE: FAMB search form 
 *
 */
 .module__famb-search {
 	// position: absolute;
 	// top: 0;
 	// width: 100%;
 }
.famb__search {
	// width: 385px;
	// overflow: visible;
	// position: absolute;
	// right: 0;
	margin: pxToRem(26) 0 pxToRem(26) pxToRem(26);
	padding: pxToRem(40) pxToRem(40);

	.sq-form-section {
		float: none;
		width: 100%;
		margin: 0 0 pxToRem(30) 0;
	}
	.sq-submit-button {
		float: none;
		padding: 0;
	}
}
.famb__search-legend {
	font-size: pxToRem(26);
}
.famb__search-description {
	margin: pxToRem(5) 0 pxToRem(16) 0;
}



/* 
 * 
 * MODULE: Task tiles 
 *
 */
.module__tasks {
	margin: 0 0 pxToRem(20) 0;
}
.task__tiles {
	// margin: 0 pxToRem(20) 0;
	// padding: pxToRem(30) 0;
	height: auto;
	overflow: hidden;
}
.task__tiles-item {
	width: 25%;
}
.task__tiles-link {
	// padding: pxToRem(40) pxToRem(10);
	min-height: pxToRem(140);
}
span.task__tiles-link-text {
	margin: 0;
}
.task__tiles-icon {
	// margin: 0 0 pxToRem(11) 0;
	height: 55px;
}
.task__tiles-title {
	font-size: pxToRem(18);
}




/* 
 * 
 * MODULE: Tabs display
 *
 */
.module__tabs {
  padding: 0;
  overflow: hidden;
}
.mb__tabs {
	.slick-arrow {
		border: none;
		display: block;
		width: 48px;
		height: 48px;
		// background: $secondary-blue;
		// color: #fff;
		z-index: 1;
	}

	@media only screen and (min-width:1140px) {
		.slick-next {
			right: -16px;
			margin-top: 0;
		}
		.slick-prev {
			left: -16px;
			margin-top: 0;
		}
	}
}
.mb__tabs-content {
	margin: 0 -14px;
	width: calc(100% + 28px);
	.card {
		width: calc(33.33%);
		// margin: 0 14px;
		margin: 0;
	}
}
.mb__tabs-content {
	overflow: visible;
}

.mb__tabs-content .slick-list {
	// overflow: visible;
}
.mb__tabs-content .slick-list:before {
	content: "";
    display: block;
    position: absolute;
    width: 1000%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.75;
    background: #F4F6FA;
    z-index: 999;
    left: -1000%;
    z-index: 99;
}
.mb__tabs-content .slick-list:after {
	content: "";
    display: block;
    position: absolute;
    width: 1000%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.75;
    background: #F4F6FA;
    z-index: 999;
    left: auto;
    right: -1000%;
    z-index: 99;
}



.module__why-a-member {
	overflow: hidden;
	width: 100%;
	float: none;
	.card {
		float: left;
		overflow: hidden;
		width: 70%;
		text-align: left;
		flex-direction: row;
		.mb_icon {
			width: 50%;
			position: relative;
			right: auto;
			top: auto;
			img {
				width: 80%;
			}
		}
		.card-block {
			width: 50%;
			padding-top: 0;
		}
		.card-title {
			margin-top: 0;
		}
		.card-text {
			padding-right: 0;
		}
	}
}
.why-a-member__list {
	float: left;
	width: 30%;
}

.mb__sponsors {
	padding: 2.5rem 0;
	
	h2 {
		// font-size: pxToRem(20);
		// width: 8rem;
		// text-align: left;
		// margin: 0 2rem 2rem 0;
		// flex-basis: 0;
	}
	.mb__sponsors-link {
		text-align: center;
		min-width: 0;
		// margin: 0;
		img {
			// width: auto;
		}
	}
}



/*
 *
 * Content GRID flexbox
 *
 */
.module__content-grid {
	padding: 0;
}
.content-grid__item {
	width: calc(33.33% - 20px);
	margin: 0 pxToRem(30) pxToRem(30) 0;

	&:nth-child(2n) {
		margin-right: pxToRem(30);
	}
	&:nth-child(3n) {
		margin-right: 0;
	}
}
.content-grid__columns-2 {
	.content-grid__item {
		width: calc(50% - 15px);
		margin: 0 pxToRem(30) pxToRem(30) 0;
		&:nth-child(2n) {
			margin-right: 0
		}
	}
}
.module__grid-carousel {
	padding-left: 0;
	padding-right: 0;
	overflow: hidden;
}
.grid-carousel__container {
	margin-bottom: 0;
}
.grid-carousel__container .slick-list {
	overflow: visible;
}
.grid-carousel__container .slick-list:before {
	content: "";
    display: block;
    position: absolute;
    width: 1000%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.75;
    background: #E5EBF4;
    z-index: 999;
    left: -1000%;
    z-index: 99;
}
.grid-carousel__container .slick-list:after {
	content: "";
    display: block;
    position: absolute;
    width: 1000%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.75;
    background: #E5EBF4;
    z-index: 999;
    left: auto;
    right: -1000%;
    z-index: 99;
}

/*
 *
 * Global search styling
 *
 */
.global__search {
    right: -25px;
    width: 445px;
    top: 100%;
    height: 85px;
    left: auto;
    bottom: 0;
	right: 20px;
    padding: 10px 25px;
    position: absolute;
    overflow: visible;
    &:before {
			// content: '';
			position: absolute;
			display: block;
			left: auto;
			top: -14px;
			right: 43px;
			margin: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 14px 14px 14px;
			border-color: transparent transparent #1d3273 transparent;

			@media only screen and (min-width: 1250px) {
				right: 185px;
			}
		}
	form {
		position: relative;
    right: 0;
    top: 0;
    z-index: 2;
    margin: 0;
    width: 100%;
    .global__search-inner {
    	margin: 0;
    }
    .close-search {
    	display: none;
    }
	}
}

.search-toggler.active {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		display: block;
		left: auto;
		top: 53px;
		right: auto;
		margin: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 14px 14px 14px;
		border-color: transparent transparent #1d3273 transparent;
	}
}

/*.info_bar-list-item a.search-toggler {
	padding-right: 0;
}*/

@media only screen and (min-width: 1200px) {
	.info_bar-list {
		display: flex;

		.user__shopping-cart-toggler {
			order: 1;
		}

		.members_area {
			order: 3;
		}

		.search {
			order: 2;
		}

		.menu {
			display: none;
			visibility: hidden
		}
	}

	.navigation__list-item > a {
		font-size: pxToRem(15);
	}
}

@media only screen and (min-width: 1330px) {
	.info_bar-list {
		.members_area {
			.member-name {
				display: inline-block;
			}
		}
	}
}


.mb__member-login {
	display: block;
	height: 0;
	overflow: hidden;
	opacity: 0;
	@include sq-transition(all .3s linear);
	&.active {
		height: 700px;
		overflow: visible;
		opacity: 1;
	}
	.row__inner {
		position: relative;
		@include sq-flex-box();
		flex-wrap: wrap;
	}
}
.mb__member-panel {
	width: 50%;
}

.module__page-banner.page-banner-content {
	min-height: auto;
	.page-banner__item {
		height: 100%;
	}
	.page-banner__details {
		padding-left: 0;
		padding-right: 0;
	}
	&.page-banner-has-image {
		.page-banner__image {
			position: absolute;
			/*height: 100%;*/
			width: auto;
		}
		.page-banner__item {
			height: 100%;
		}
	}

}
.module__page-banner.page-banner-content .page-banner__image-container{
	position: absolute;
	top: 0;
	bottom: 0;
}

.module__page-banner.page-banner-content.page-banner-has-image .page-banner__details,
.module__page-banner.page-banner-content.page-banner-has-image .page-banner__details--new {
	bottom: 0;
	top: auto;
    position: absolute;

	h1 {
		font-size: pxToRem(32);
	}
}

.page-banner-content h1 {
	font-size: pxToRem(44);
	font-weight: bold;
}

.aside__extra-content .maps__infowindow-content {
	width: 10rem;

	img, p {
		display: none;
	}
}

@media only screen and (max-width: 1150px) {
	.module__page-banner.page-banner-content .page-banner__details {
		padding-left: 1em;
		padding-right: 1em;
	}
	.max-width-desktop {
		margin: 0 20px;
	}
	.row__global .row__inner {
		// padding: 0 20px;
	}
}

@media only screen and (min-width: 1140px) {
	.grid-carousel__item {
		height: 350px;
	}

	body .grid-carousel__item-title{
		padding: 300px 20px 0 20px;
	}
}

.mb-share-icons {
	display: block;
}

/*
 *
 * Module CTA Title
 *
 */
 .module__cta-tile {
	 position: absolute;
	 top: 0;
	 width: 100%;
	
	.max-width-desktop {
      position: relative;
	  max-width: 1400px;
	  margin: 0 auto;
	}

	.cta-tile {
		&__wrapper {
			flex-direction: column;
			padding: 0;
			width: pxToRem(400);
			max-height: pxToRem(450);
			position: absolute;
			right: 0;
			z-index: 2;
			overflow: visible;
			margin: 0 pxToRem(20);
		}

		&__link {
			width: 100%;
			border-bottom: 0;
			border-right: 0;

			&:not(:last-child) {
				border-bottom: pxToRem(2) solid $base-white;
			}

			&:nth-child(1),
			&:nth-child(3) {
				border-right: 0;
			}

			&:nth-child(1),
			&:nth-child(2) {
				// border-bottom: pxToRem(2) solid $base-white;
			}
		}

		&__item {
			flex-direction: row;
			align-items: center;
			// justify-content: space-between;
		}

		&__icon {
			margin: 0 pxToRem(48) 0 pxToRem(16);
			width: 15%;

			svg {
				width: 100%;
			}
		}

		&__content {
			text-align: left;
			width: 75%;
		}
	}
}

iframe {
	&.custom-iframe {
		height: 600px;
	}
}