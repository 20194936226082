// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #666;
$base-font-stack: 'Open Sans', sans-serif;

$base-blue: #3652a3; // denim blue
$secondary-blue: #1d3373; // cobalt blue
$base-light-blue: #0098d6; // cerulean
$base-orange: #f8991d; // squash
$base-orange-rgba: rgba(248, 153, 29, 0.9);
$base-green: #6db277; // tea
$base-white: #fff;
$base-white-rgba: rgba(255, 255, 255, 0.9);
$base-grey: #919dbe; // bluegrey
$base-background: #f6f6f9;
$base-black: #000;
$base-red: red;

$base-blue-hover: $secondary-blue;
$base-orange-hover: #fcaf4a;
$base-green-hover: #55945e;
$base-light-blue-hover: #0383b8;
$base-white-hover: $base-grey;
$base-red-hover: #ad0202;

$max-width: 1140px; // px


// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Bootstrap Variables
$blue: #000D35;

$enable-rounded: false;