/* Module: S */
body {
	position: relative;
	z-index: 1;
}
body a {
	color: $base-blue;
	&:hover, &:focus {
		color: $base-blue;
	}
}
body .button:focus,
body .button:hover {
    text-decoration: none;
}
body .mfp-container{
	cursor: pointer
}
body td {
	border-bottom: 1px solid rgba(145, 157, 190, 0.24);
}
.page-wrapper {
	position: relative;
	overflow-x: hidden;
}

#page-wrapper {
	// overflow: hidden;
}

.row__global {
	display: block;
	width: 100%;
	clear: both;
	@extend .clearfix;
	.row__inner {
		padding: 0;
	}
}

.info_bar {
	background: $base-blue;
}
.info_bar-list {
	margin: 0;
	padding: 0;
	position: relative;
	list-style: none;
	@include sq-flex-box();
	justify-content: flex-end;
	align-items: center;
	a {
		color: $base-white;
		text-decoration: none;
		@include sq-svg-a-color($base-white, $base-orange);
	}
	.member_login-toggler {
			@include sq-svg-a-color($base-blue, $base-orange);
	}

	svg {
		height: 30px;
	}

	.member-icon {
		display: none;
	}
	
	.public-icon {
		// height: 40px;
	}
}

.member_access {
	.public-icon {
		display: none;
	}
	.member-icon {
		display: initial;
	}

	.members_area {
		a {
			color: $base-blue;
			text-decoration: none;
			@include sq-svg-a-color($base-blue, $base-orange);
		}
	}
}

@media only screen and (max-width: 784px) {
	.member_access .display-vallage-map .maps__infowindow-content {
		width: 15rem;

		.title {
			margin: 0
		}

		img {
			display: none;
		}

		p {
			margin: 1rem 0; 
		}
	}
}

@media only screen and (max-width: 540px) {
	.member_access .display-vallage-map .maps__infowindow-content {
		width: 10rem;
	}
}

.info_bar-list-item {
	padding: 0 pxToRem(8);
	position: relative;

	&:last-child {
		padding-right: 0;
	}
	
	> a {
		display: block;
		// padding: pxToRem(8) 0 pxToRem(4);
		// width: pxToRem(64);
		width: auto;
		// padding-left: pxToRem(6);
		// padding-right: pxToRem(6);
		text-align: center;
		height: 100%;
		color: $blue;
	}
	&:after {
		// content: '';
		display: block;
		position: absolute;
		width: 1px;
		height: 27px;
		opacity: 0.2;
		background: $base-white;
		right: 0;
		top: 10px;
	}
	&:last-child {
		&:after {
			display: none;
		}
	}
}
.mb_icon {
	line-height: 0;
	.fa {
		font-size: 30px;
	}
}
.members_area {
	// background: $base-orange;
	.member-name {
		display: none;
	}
}
.member_access .members_area {
	// background: #ebeef5;
}
.user__personalisation > a {
	position: relative;
	&:after {
			content: none;
			position: absolute;
			z-index: auto;
			bottom: -5px;
			left: 50%;
			margin: 0 0 0 -8px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 8px 8px 8px;
			border-color: transparent transparent $base-orange transparent;
			animation: MoveUpDown 1s linear infinite;
		}
		&:before {
			content: none;
			position: absolute;
			z-index: auto;
			bottom: -4px;
			left: 50%;
			margin: 0 0 0 -12px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 12px 12px 12px;
			border-color: transparent transparent #fff transparent;
		}
}
@keyframes MoveUpDown {
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: -3px;
  }
  100% {
    margin-bottom: 0;
  }
}

.header {
	.row__global .row__inner {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 20px 16px;
	}
}

.mb_counter {
		color: $blue;
		@include sq-border-radius(50%);
		// background: $base-orange;
		display: block;
		position: absolute;
		z-index: auto;
		height: pxToRem(22);
		width: pxToRem(22);
		text-align: center;
		line-height: pxToRem(22);
		font-size: 10px;
		font-weight: bold;
		top: pxToRem(-2);
    	left: pxToRem(27);
}

.site-logo {
	display: block;
	// padding: 0 0 0 pxToRem(20);
	a {
		display: block;
		// padding: 20px 0 22px 0;
		width: pxToRem(120);
	}
}
.header__extras {
	display: block;
	margin-left: auto;
	// position: absolute;
	// right: 0;
	// top: 30px;
	a {
		// color: $base-blue;
		color: $blue;
		@include sq-svg-a-color($blue, $base-orange);
	}

	.shopping_cart-toggler {
		
	}
}

.navigation {
	position: absolute;
	left: -100%;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	background: $secondary-blue;
	&.active {
		left: 0;
		overflow: auto;
		z-index: 999;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		// .navigation ul {
			@media only screen and (min-width:1200px) {
				@include sq-flex-box();
			}
		// }
	}
	a {
		color: $base-white;
		text-decoration: none;
		display: block;
	}

	&__link {
		font-weight: 400;
	}
}
.navigation__list {
	display: block;
}
.mobile_nav {
	padding: 20px 0;
	.navigation__list-item {
		position: relative;
		a {
			padding: 20px;
			width: calc(100% - 50px);
			&:hover, &:focus {
				color: $base-orange;
			}
		}
		a.navigation__toggler {
			padding: 0;
			width: 50px;
			position: absolute;
			right: 0;
			top: pxToRem(12);
			text-align: center;
			font-size: pxToRem(26);
		}
	}
	.navigation__has-megamenu.active {
		background: #3652a3;
	}
}
.mega-menu {
	display: none;
	padding: pxToRem(0) pxToRem(20);
	a {
		padding: 0.9375rem .7rem;
		width: 100%;
		&:hover, &:focus {
			background: $base-blue;
			color: #fff;
		}
	}
	&.active {
		display: block;
		z-index: 2;
	}
}
.navigation__list-item > a {
	// font-weight: bold;
}
.mega-menu__feature {
	display: block;
	position: relative;
	color: $base-white;
	max-width: 320px;
	/*max-height: 320px;*/
	margin: pxToRem(40) 0 pxToRem(40) pxToRem(32);
	background: #5671b6;
	&:before {
		content: "FEATURE";
		display: block;
		position: absolute;
		top: 0; left: 0;
		background: $base-orange;
		padding: 5px 10px;
		color: $base-blue;
	}
	a {
		color: #fff;
		background: none;
	}
}
.mega-menu__feature-item-image {
	margin: 0 auto;
	display: block;
}
.mega-menu__feature-item-title {
	font-size: pxToRem(21);
	font-weight: normal;
	padding: 0 pxToRem(20);
	margin: 0;
	a {
		padding: pxToRem(15) 0;
	}
}
.mega-menu__feature-item-description {
	font-size: pxToRem(15);
	padding: 0 pxToRem(20);
}

body a.close_toggler {
	padding: 1rem 1.25rem;
	clear: both;
	overflow: hidden;
	@include sq-svg-a-color($base-white, $base-orange);
	.mb_icon {
		margin: 0 1rem 0 0;
	}
	span {
		display: block;
		float: left;
	}

	@media only screen and (min-width:1200px) {
		display: none;
	}
}




/*
 *
 * Global search styling
 *
 */
.search-toggler {
	.toggle-close {
		display: none;
	}
	&.active {
		.toggle-close {
			display: inline-block;
			margin: pxToRem(4) 0 0 0;
		}
		.toggle-open {
			display: none;
		}
	}
}
.global__search {
	display: none;
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 10px;
	overflow: hidden;
	z-index: 8;
	form {
		position: absolute;
		right: 0;
		top: 30px;
		z-index: 2;
		width: 90%;
		margin: 0 5%;
	}
	&.active {
		display: block;
		&:after {
			content: "";
			display: block;
			position: absolute;
			background: $secondary-blue;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}
}
.global__search a.close-search {
	position: absolute;
	top: 10px;
	right: 2px;
	z-index: 99;
	color: #fff;
	padding: 0;
	.mb_icon {
		margin: 0;
	}
}
.global__search-inner {
	margin: 80px 0 0 0;
	@include sq-flex-box();
	border-bottom: 2px solid #fff;
	padding: 0 0 10px 0;
}
.global__search-label {
	margin: 0 10px 0 0;
	float: left;
	color: #fff;
	font-size: pxToRem(21);
  line-height: pxToRem(42);
}
.global__search-input {
	border: none;
	padding: 10px;
	margin: 0 15px 0 0;
	float: left;
	width: calc(100% - 15px);
	color: $base-grey;
	background: none;
	&::-webkit-input-placeholder {
		color: $base-grey;
		font-weight: 100;
	}
	&::-moz-placeholder {
		color: $base-grey;
		font-weight: 100;
	}
	&:-ms-input-placeholder {
		color: $base-grey;
		font-weight: 100;
	}
	&:-moz-placeholder {
		color: $base-grey;
		font-weight: 100;
	}
}
.global__search-submit {
	padding: 0;
	margin: 7px 0 0 0;
	border: none;
	background: none;
	float: left;
}



/*
 *
 * Global Footer styling
 *
*/
.footer {
	position: relative;
	z-index: 1;
	background: $blue;
	color: $base-white;
	padding: pxToRem(30) pxToRem(20);
	text-align: center;

	a {
		color: $base-white;
		text-decoration: none;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}
}
.footer__list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	li {
		display: inline-block;
	}
}
.mb__social-links {
	overflow: hidden;
	margin: pxToRem(20) 0;
	li {
		// display: block;
		// float: left;
		margin: 0 pxToRem(12) pxToRem(12) 0;

		a {
			svg {
				width: pxToRem(40);
				height: pxToRem(40);
			}
		}
	}
}
.mb__member-links {
	width: pxToRem(100%);
	li {
		margin: 0 0 6px 0;
		display: block;
	}
	a[class*="button__"] {
		display: block;
		text-align: center;
	}
}

.mb__legal-links {
	margin: pxToRem(20) 0 0 0;
	// position: relative;
	font-size: pxToRem(12);
	// padding: 0 0 5px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	li {
		padding: 6px;
		display: block;

		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				color: $base-white;
			}
		}
	}

	&--contact {
		flex-direction: row;
		font-size: pxToRem(16);
		font-weight: bold;
		margin: 0 0 pxToRem(30) 0;

		li {
			padding: 0 8px;
			position: relative;

			&:after {
				content: "";
				display: block;
				background: #fff;
				position: absolute;
				right: 0;
				font-weight: bold;
				width: 2px;
				height: 18px;
				top: 4px;
			}

			a {
				text-decoration: none;
				white-space: nowrap;

				&:hover {
					text-decoration: underline;
				}
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}
.mb__chat {
	display: block;
	width: pxToRem(63);
	height: pxToRem(55);
	background: $base-light-blue;
	position: absolute;
	right: 0;
	bottom: 0;
}





/*
 *
 * Button styles - general styling
 *
 */
a[class*="button"]{
	display: inline-block;
	padding: pxToRem(15) pxToRem(25);
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	&:hover, &:focus {
		text-decoration: none;
	}
}
.button__blue {
	background: $base-blue;
	color: #fff;
	&:hover, &:focus {
		background: $base-blue-hover;
		color: #fff;
	}
}
body .button__orange {
	background: $base-orange;
	color: #181818;
	&:hover, &:focus {
		background: $base-orange-hover;
	}
}
.button__green {
	background: $base-green;
	color: $base-white;
	&:hover, &:focus {
		background: $base-green-hover;
		color: $base-white;
	}
}
.button__light-blue {
	background: $base-light-blue;
	color: #fff;
	&:hover, &:focus {
		background: $base-light-blue-hover;
		color: #fff;
	}
}
.button__white {
	background: $base-white;
	color: #181818;
	&:hover, &:focus {
		background: #D4D8E5;
	}
}
.button__red {
	background: $base-red;
	color: #fff;
	&:hover, &:focus {
		background: $base-red-hover;
		color: #fff;
	}
}

.button__navy {
	background: $blue;
	color: $base-white;
	&:hover, &:focus {
		background: $base-blue-hover;
		color: $base-white;
	}
}


/*
 *
 * MODULE: Page banner
 *
 */
.module__page-banner {
	display: block;
	overflow: hidden;
	width: 100%;
	min-height: 180px;
	position: relative;
	.slick-dots {
		position: absolute;
		top: pxToRem(12);
		right: pxToRem(20);
		margin: 0;
		padding: 0;
		list-style: none;
		li + li {
			margin-left: 5px;
		}
		.slick-dots__item {
			border: none;
			display: block;
			width: 12px;
			height: 12px;
			@include sq-border-radius(50%);
			background: $base-white;
		}
		.slick-active .slick-dots__item,
		.slick-dots__item:hover,
		.slick-dots__item:focus {
			background: $base-blue;
		}
	}

	.slick-arrow {
		border: 0;
		background-color: transparent;
		cursor: pointer;
		z-index: 1;
		margin: 0;
		padding: 0;
		top: auto;
		bottom: 50%;
	}

	.slick-prev {
		left: 0;
	}

	.slick-next {
		right: 5px;
		// top: 37px;
	}
}
.page-banner__item {
	position: relative;
	height: 180px;
}
.page-banner__image {
	width: 100%;
	max-width: 100%;
	position: absolute;
	z-index: 1;
}
.page-banner__link {
	color: $base-white;
	text-decoration: none;
	display: block;
	&:hover, &:focus {
		text-decoration: none;
	}
}
a.page-banner__link {
	padding: pxToRem(8);
	font-size: pxToRem(14);
	margin-right: auto;

	&:hover,
	&:focus {
		background-color: $base-white;
		color: $blue;
	}
}
.page-banner__inner {
  display: block;
  height: 100%;
  padding: 0;
  position: relative;
  z-index: auto;
}
.page-banner__details {
	background: $base-orange-rgba;
    color: #fff;
    position: absolute;
    padding: 1.25rem 0.625rem 1.25rem 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
    right: 1.25rem;

	&--new {
		color: $base-white;
		padding: pxToRem(20) pxToRem(30);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		width: 100%;
		height: 100%;
		background: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		// text-shadow: rgba(0,0,0,0.4) 0px 4px 3px;
	}

	&--dark {
		color: $base-black;
	}

	&-wrapper {
		max-width: pxToRem(600);
		// margin: 0 auto;
	}
}
.page-banner__details__inner-wrapper{
	display: flex;
	justify-content: space-between;
}
.page-banner__title {
	font-size: pxToRem(18);
	margin: 0 0 pxToRem(16) 0;
	padding: 0;
	letter-spacing: -1.3px;
	font-weight: bold;
}
.page-banner__description {
	display: none;
	font-size: pxToRem(18);
}
.page-banner-has-image + .mb__breadcrumbs-wrapper,
.mb__breadcrumbs-wrapper {
	margin: 1rem 0;
	padding: 0 1.25rem;
	
	h1 {
		margin: 1rem 0;
	} 
}
.page-banner__item img {
	z-index: 0;
	height: 100%;
	object-fit: cover;
}
.module__page-banner.page-banner-content {
	margin: 0;
	min-height: auto;
	&.page-banner-has-image {
		min-height: 180px;
		.page-banner__item {
			height: auto;
		}
		.page-banner__image {
			position: relative;
		}
		.page-banner__details {
			position: relative;
		}
	}
	.page-banner__details {
		background: none;
		color: inherit;
		position: relative;
		padding: pxToRem(20) pxToRem(15) pxToRem(20) pxToRem(15);
		left: auto;
		right: auto;
		bottom: 0;
		top: auto;

		&--new {
			color: $base-black;
			max-width: pxToRem(700);

			h1 {
				// color: $base-blue;
				color: inherit;
				font-size: pxToRem(24);
				margin-bottom: pxToRem(16);
			}
		}

		&--dark {
			color: $base-black;
		}
	}
	.page-banner__item {
		height: auto;
	}
	h1 {
		color: $base-blue;
		font-size: pxToRem(36);
		margin: pxToRem(16) 0;
	}
}
.page-banner__image-container{
    background-size: cover;
    width: 100%;
    position: relative;
    z-index: auto;
    background-position: center;
    min-height: 180px;
}
.page-banner-content.page-banner-has-image .mb__breadcrumbs {
	display: none;
}
.page-banner-has-image {
	.page-banner__details {
		background: none;
		color: $base-white;
		position: relative;
		padding: pxToRem(0) pxToRem(10) pxToRem(0) pxToRem(20);
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		* {
			position: relative;
			z-index: auto;
		}
		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: auto;
			height: 100%;
			width: auto;
			top: 0;
			bottom: 0;
			left: -100%;
    		right: -100%;
			background: $base-white-rgba;
		}

		&--new {
			.row {
				flex-direction: column;
	
				.col-xs-12 {
					width: 100%;
					flex: unset;
					max-width: none;
	
					.page-banner__buttons {
						display: flex;
						flex-direction: column;
						margin-bottom: 0;
	
						a {
							display: block;
							width: fit-content;
							margin-right: pxToRem(20);
							// border-radius: pxToRem(10);
							padding: pxToRem(12) pxToRem(24);
							font-weight: bold;
							text-transform: uppercase;
							text-shadow: none;
							min-width: pxToRem(250);
	
							&.button__white {
								border: 1px solid $base-black;
	
								&:hover,
								&:focus {
									background-color: $base-black;
									color: $base-white;
								}
							}
	
							&.button__orange {
								color: $base-white;
	
								&:hover,
								&:focus {
									color: $base-orange;
									background-color: $base-white;
								}
							}

							&.button__navy {
								&:hover, &:focus {
									background: $base-white;
									color: $blue;
								}
							}
	
							&:hover,
							&:focus {
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		&--dark {
			color: $base-black;
		}
	}

	.page-banner__description {
		color: inherit;
		font-size: pxToRem(16);
		font-weight: bold;
	}
}
.page-banner__buttons {
	a {
		width: 100%;
		margin: 0 0 8px 0;
	}
}
.page-banner-has-image.page-banner-no-title .page-banner__details {
	display: none;
}

.page-banner__items--fix {
	position: relative;
    width: 100%;
}

.max-width-wide-desktop {
	max-width: pxToRem(1400);
    margin: 0 auto;
    padding: 0 16px;
}

.no-max-width {
	max-width: unset;
    margin: 0;
}

/*
 *
 * MODULE: FAMB search form
 *
 */
.famb__search {
	display: block;
	margin: pxToRem(20);
	padding: pxToRem(20);
	background: rgba(255, 255, 255, .7);

	.sq-form-section {
		display: block;
		width: 100%;
		color: $base-blue;
		border-bottom: 2px solid $base-blue;
		margin: 0 0 pxToRem(20) 0;
		position: relative;
		&:after {
			content: "";
			display: block;
			width: 33px;
			height: 35px;
			position: absolute;
			right: 0;
			top: 0;
			background: url({{file_dest}}/type-of-wrok-icon.svg) no-repeat 0 0;
		}
		input[type="text"]{
			background: none;
			border: none;
			width: calc(100%);
			padding: 0 35px 0 10px;
			height: 40px;
			color: $base-black;
			&::-webkit-input-placeholder {
			color: $base-blue;
			font-weight: 100;
			}
			&::-moz-placeholder {
				color: $base-blue;
				font-weight: 100;
			}
			&:-ms-input-placeholder {
				color: $base-blue;
				font-weight: 100;
			}
			&:-moz-placeholder {
				color: $base-blue;
				font-weight: 100;
			}
		}

		/*& + .sq-form-section {
			&:after {
				background: url({{file_dest}}/location-blip.svg) no-repeat 0 0;
				width: 25px;
			}
		}*/
	}
}
.famb__search-legend {
	color: $base-blue;
	font-size: pxToRem(18);
}
.twitter-typeahead {
		display: block;
		width: 100%;
	}
.tt-menu {
		background: #fff;
		width: 100%;
		@include sq-box-shadow(0px 0px 9px 0px rgba(0,0,0,0.12));
		padding: 0 0 10px 0;
	}
	.tt-title {
		font-size: pxToRem(16);
		padding: pxToRem(10);
		font-weight: bold;
		color: #fff;
		display: block;
		background: $base-blue;
	}
	.tt-category {
		padding: pxToRem(10) pxToRem(10);
		font-weight: bold;
		color: $base-grey;
		display: block;
	}
	.tt-suggestion {
			padding: pxToRem(5) pxToRem(10);
	}
	.tt-selectable {
		cursor: pointer;
	}
.sq-submit-button {
	[type="submit"],
	[type="button"] {
		background: $base-blue;
		padding: 10px 20px;
		border: none;
		color: $base-white;
		&:hover, &:focus {
			background: $secondary-blue;
		}
	}
}
.search-results__facets__famb li{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.search-results__facets__famb li a{
    width: 90%;
    word-break: break-word;
}

/*
 *
 * MODULE: Task tiles
 *
 */
.task__tiles {
	list-style: none;
	margin: pxToRem(20) 0;
	padding: 0;
	@include sq-flex-box();
	flex-wrap: wrap;
}
.main__content ul li.task__tiles-item {
	width: calc(50% - 1px);
	margin: 0 1px 1px 0;
}
.task__tiles-item{
	width: 50%;
}
.task__tiles-link {
	display: block;
	// background: $base-white;
	color: $base-blue;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	padding: pxToRem(16);
	min-height: pxToRem(155);
	font-weight: 400;
	&:hover, &:focus {
		color: $base-orange;
		text-decoration: none;
	}
}
.task__tiles-icon {
	display: block;
	margin: 0 0 pxToRem(12) 0;
	height: 55px;
}
.task__tiles-icon img{
	max-height: 100%;
}
.task__tiles-title {
	display: block;
}


/*
 *
 * MODULE: Tabs display
 *
 */
.module__tabs {
  display: block;
  padding: 0 pxToRem(20);
  position: relative;
  width: 100%;
//   background: #F4F6FA;
}
.mb__tabs {
	.slick-arrow {
		border: none;
		display: none;
		width: 30px;
		height: 30px;
		// background: $secondary-blue;
		color: $base-orange;
		background-color: transparent;
		cursor: pointer;
		z-index: 1;
		top: calc(50% - 61px);
		padding: 0;
		svg {
			// margin: pxToRem(5) 0 0 0;
			margin: 0;
			path {
				fill: $base-orange;
			}
		}
	}
	.slick-next {
		right: -9px;
		margin-top: 0;
		display: inline-block;
	}
	.slick-prev {
		left: -9px;
		margin-top: 0;
		display: inline-block;
	}
}

.mb__tabs-list {
	list-style: none;
	margin: 0 0 pxToRem(28);
	padding: 0;
	display: block;
	text-align: center;
	// border-bottom: 4px solid #E1E5F1;
	li {
		// margin: 0 0 -4px 0;
		background-color: $blue;
		a {
			display: block;
			padding: pxToRem(16);
			border-bottom: 4px solid transparent;
			text-transform: uppercase;
			// color: $base-grey;
			color: $base-white;
			text-decoration: none;
			font-weight: 500;
			&.active, &:hover, &:focus {
				// border-bottom: 4px solid $base-orange;
				// color: $base-blue;
				background-color: $base-grey;
			}
		}
	}
}
.mb__tabs-content {
	display: none;
	width: 100%;
	position: relative;
	padding-bottom: pxToRem(75);
	margin: 0 0 pxToRem(20) 0;
	.slick-track {
		padding: 0 0 10px 0;
		display: flex;
		align-items: stretch;
	}

	.slick-initialized .slick-slide {
		display: flex;
		align-items: stretch;
		height: auto;
	}

	.mb__more-link {
		// position: absolute;
		display: block;
		background: $base-orange;
		// border-radius: pxToRem(20);
		text-transform: uppercase;
		color: $base-white;
		padding: pxToRem(12);
		width: pxToRem(180);
		text-align: center;
		margin: 0 auto;

		&:hover,
		&:focus {
			background: $base-white;
			color: $base-orange;
			text-decoration: none;
		}
	}
	&.active {
		display: block;
	}
	.card {
		border: none;
    	border-radius: 0;
		width: calc(50% - 28px);
		margin: 0 14px;
		min-height: pxToRem(330);
		@include sq-box-shadow(0px 0px 9px 0px rgba(0,0,0,0.12));
		margin: 0;
		height: 100%;
		&:hover {
			background: $base-blue;
			a {
				.card-title,
				.card-text,
				.card-location,
				.card-region {
					color: $base-white;
				}
			}
		}
	}
	&.no-bg-hover .card:hover {
		background: #fff;
	}
	.card-block {
		padding: pxToRem(40) pxToRem(40) 0;
		margin: 0;
		position: relative;
	}
	.card.with-image {
		&:hover {
			background: $base-white;
		}
		.card-img {
			width: 100%;
			position: relative;
			margin: 0;
		}
		.card-img-top {
			width: 100%;
			margin: 0;
		}
		.card-title {
			position: absolute;
			left: 0;
			min-height: 75px;
			top: auto;
			bottom: 0;
			overflow: hidden;
			width: 100%;
			padding: 20px 40px;
			background: rgba(29, 51, 115, 0.75);
			color: #fff;
			margin: 0;
		}
		.card-block {
			padding: 0 1rem;
			margin: 0;
			position: absolute;
	    height: 100%;
	    width: 100%;
	    a {
	    	position: absolute;
		    height: 100%;
		    width: 100%;
		    &:hover, &:focus {
		    	text-decoration: none;
		    	.card-title {
		    		top: 0;
		    	}
		    	.card-text {
	  		    display: block;
				    position: absolute;
				    bottom: auto;
				    top: 60px;
				    padding: 0 40px;
				    color: $base-white;
		    	}
		    	.card-date {
		    		color: $base-orange;
		    	}
		    }
	    }
		}
		.card-text {
			display: none;
		}
		.card-date {
			padding: pxToRem(20) pxToRem(40) 0;
		}
	}
}
.card{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.card-block{
    padding: 0 1rem;
}
.card a > .card-title {
	color: $base-blue;
}
.card-title {
	font-size: pxToRem(20);
	line-height: pxToRem(29);
	margin: pxToRem(20) 0;
	color: $base-blue;
	h5{
		text-transform: capitalize;
	}
}
.card-text {
	color: $base-font-colour;
	margin: pxToRem(20) 0;
	&:last-child {
		margin: pxToRem(20) 0;
	}
}
.card-date {
	color: $base-orange;
	font-weight: bold;
}
.card-location {
	color: $base-font-colour;
	font-weight: bold;
	position: relative;
	padding: 0 0 0 25px;
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		width: 25px;
		height: 22px;
		background: url(mysource_files/location-blip-small.svg) no-repeat 0 0;
	}
}
.card-highlight-text {
	color: $base-orange;
	font-size: pxToRem(13);
	font-weight: bold;
	text-transform: uppercase;
}
.card-region {
	margin-top: -0.375rem;
	margin-bottom: 0;
	text-transform: capitalize;
  font-size: 1rem;
}

.card-button {
	color: $base-white;
	background-color: $blue;
	font-weight: bold;
    text-transform: uppercase;
    // border-radius: pxToRem(10);
}

.mb__more-link {
	font-weight: bold;
	padding: 0 0 0 35px;
	font-size: pxToRem(18);
	&:before {
		// content: "";
		display: block;
		position: absolute;
		left: 0;
		width: 25px;
		height: 22px;
		background: url(mysource_files/view-all-arrow.svg) no-repeat 0 0;
	}
}

.fine-print-text{
    font-size: .75rem;
}


.module__why-a-member {
	background: #e6e9f3;
	overflow: hidden;
	padding: pxToRem(40) 0;
	.card {
		background: none;
		text-align: center;
		border: none;
		color: $base-blue;
		p {
			color: $base-blue;
			font-size: 17px;
		}
		.card-title {
			font-size: pxToRem(26);
    	font-weight: bold;
		}
	}
}
.why-a-member__list {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		a {
			display: block;
			position: relative;
			padding: 0 0 0 pxToRem(30);
			margin: 0 0 pxToRem(15) 0;
			font-size: pxToRem(17);
			font-weight: bold;
			color: $base-blue;
			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: pxToRem(6);
				width: 13px;
				height: 13px;
				background: url(mysource_files/view-all-arrow-small.svg) no-repeat 0 0;
			}
		}
	}
}

.module__advert {
	text-align: center;
	background: #e6e9f3;
	padding: pxToRem(20) pxToRem(20) pxToRem(40);
}


.module__sponsors {
	display: block;
	clear: both;
	background: #fff;
}
.mb__sponsors {
	padding: pxToRem(30) pxToRem(20);
	overflow: hidden;
	h2 {
		text-transform: uppercase;
		font-size: pxToRem(20);
		// color: #9a9a9a;
		color: $base-blue;
		line-height: pxToRem(26);
		font-weight: 800;
		display: block;
		clear: both;
		text-align: center;
		width: 100%;
		margin: 0 0 pxToRem(30) 0;
		// font-family: monospace, monospace;
		// flex-grow: 1;
	}
	.mb__sponsors-link {
		margin: 0 0 2rem 0;
		text-align: center;
		display: block;
		img {
			max-height: 50px;
		}
	}
	.mb__sponsors-link.full-width {
		width: 100%;
	}
}




.mb__breadcrumbs {
	float: left;
	list-style: none;
	display: block;
	margin: 0;
	padding: 0;
	color: $base-grey;
	font-size: pxToRem(13);
	a {
		color: $base-blue;
		font-weight: bold;
	}
	li {
		display: inline-block;
		position: relative;
		border-bottom: none;
		&:after {
			content: " > ";
			color: $base-blue;
			font-weight: bold;
		}
	}
	li:last-child:after {
		content: "";
	}
}
.mb-share-icons{
	display: none;
	font-size: 0.8125rem;
	float: right;
}
.mb-share-icons i,
.mb-share-icons a{
	margin-right: .2rem;
}

.main__content {
	ul.mb__breadcrumbs {
		li {
			border-bottom: none;
		}
	}
}


.aside__navigation__list {
		margin: 1.3rem 0 0 0;
}

.aside__navigation {
	padding: pxToRem(0) pxToRem(20) pxToRem(24) pxToRem(0);
}
.aside__navigation__list {
	list-style: none;
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	ul {
		list-style: none;
		padding: 0;
	}
  > li {
  	border-bottom: 1px solid #d0d0d0;
  	&.active {
  		border-bottom: 1px solid $base-blue;
  		> a {
  			border-top: 1px solid $base-blue !important;
  			color: $base-blue;
  		}
  	}
  	a {
  		font-size: pxToRem(17);
  		padding: 1rem 0;
  		display: block;
  		color: $base-font-colour;
  		&:hover, &:focus {
  			color: $base-blue;
  		}
  	}
  	&:first-child a {
  		border-top: 1px solid #d0d0d0;
  	}
  }
  li {
  	position: relative;
  	ul.active {
			display: block;
		}
		ul {
			li a {
				padding-left: pxToRem(20);
			}
		}
		.current {
			border-left: 3px solid $base-orange;
		}
  }
}
.aside__navigation__list li.active > a {
	font-weight: bold;
}
.aside__navigation__list li ul li a:not(.aside__navigation__toggler){
	padding-right: 1rem;
}
.aside__navigation__lvl-2,
.aside__navigation__lvl-3,
.aside__navigation__lvl-4,
.aside__navigation__lvl-5 {
	display: none;
}
.aside__navigation__lvl-2 {
	a:first-child {
		font-size: pxToRem(15);
	}
	> li a {
		color: $base-blue;
		&:hover, &:focus {
			color: $base-font-colour;
		}
	}
}
.aside__navigation__lvl-3 {
	margin: 0 0 0 pxToRem(20);
	a:first-child {
		font-size: pxToRem(15);
	}
}
.aside__navigation__lvl-4 {
	margin: 0;
	a:first-child {
		font-size: pxToRem(15);
	}
}
.aside__navigation__toggler {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.aside__navigation__list-item {
	.current {
		> a {
			font-weight: bold;
		}
	}
	&.active {
		> a {
			font-weight: bold;
		}
	}
}
.module__related-links {
  ul {
    padding: 0 0 0 1rem;
    list-style-image: url(mysource_files/listed-link-arrow.svg);
    li {
      border-bottom: 1px solid #d7ddef;
      margin: 0 0 0.5rem 0;
      padding: 0.5rem 0;
    }
  }
}

/*
 *
 * Content styles
 *
 */
.main__content {
	h1 {
	  font-size: 2.25rem;
	  color: $base-blue;
	  font-weight: bold;
	  margin: pxToRem(20) 0 pxToRem(20) 0;
	}
	h2 {
	  font-size: 1.75rem;
	  color: #666;
	  font-weight: bold;
	  margin: pxToRem(20) 0 pxToRem(20) 0;
	}
	h3 {
	  margin: pxToRem(20) 0 pxToRem(20) 0;
	  font-size: 1.55rem;
	  color: #3652a3;
	  font-weight: bold;
	}
	h4 {
		font-size: 0 0 1.25rem;
	  color: #666;
	  margin: pxToRem(20) 0 pxToRem(20) 0;
	}
	h5 {
		font-size: 1.15rem;
	  color: #3652a3;
	  margin: pxToRem(20) 0  pxToRem(20) 0;
	}
	h6 {
		font-size: 1.05rem;
	  	color: #666;
	  	margin: pxToRem(20) 0 pxToRem(20) 0;
	}
	a {
		font-weight: bold;
	}
	.intro-text {
	  font-size: pxToRem(20);
	  line-height: 1.65;
	  color: $base-blue;
	}
	.pull-right {
	  margin: 0 0 0 2rem;
	}
	.pull-left {
	  margin: 0 2rem 0 0;
	}
	img + .caption-text {
		background: #fff;
		padding: 0.75rem 1rem;
	}

	ol, dl, ul {
		margin-left: .5rem;
	}

	ol {
		padding: 1rem;
		li {
			margin: 0 0 0.5rem 0;
			padding: 0.5rem 0;
		}
		ol, ul {
			padding: 0 0 0 2rem;
			margin: 0.5rem 0;
		}
	}
	ul {
		padding: 0 0 0 1rem;
		list-style-image: url(mysource_files/listed-link-arrow.svg);
		li {
			position: relative;
			margin: 0 0 0.5rem 0;
		}
		&.border-lines {
			li {
				border-bottom: 1px solid #d7ddef;
			}
		}
		ul {
			list-style-image: url(mysource_files/listed-child-link-arrow.svg);
			padding: 0 0 0 2rem;
			margin: 0.5rem 0;
			li {
				border-bottom: none;
			}
		}
	}
	.ul-table fieldset li{
		display: table;
	}
	p {
		line-height: pxToRem(28);
	}
}

.main__content{
	padding: 0 1.25rem 1rem 1.25rem;
 }
.main__content .sq-form-question-answer li:before{
	display: none;
}
.main__content .sq-form-question-answer li{
	padding: 0;
}
.main__content section{
    max-width: 71.25rem;
    margin: 0 auto;
}

/*
 *
 * Content GRID flexbox
 *
 */
.module__content-grid {
	display: block;
	/*padding: 0 pxToRem(20);*/
	h2 {
		color: $base-blue;
		font-size: pxToRem(26);
	}
}
.content-grid__container {
  @include sq-flex-box();
  flex-wrap: wrap;
  margin: 0;
}

.content-grid__container--new {
	padding: 20px;
  	background-color: $base-background;
}

/*.module__content-grid + .module__content-grid {
	margin-top: pxToRem(-50);
}*/

.content-grid__item {
	width: 100%;
	padding: 1.5rem;
	position: relative;
	background: $base-white;
	margin: 0 0 pxToRem(30) 0;

	&:last-of-type {
		margin-right: 0;
	}

	a {
		color: $base-blue;
	}
	.mb__icon {
		position: absolute;
		right: pxToRem(38);
		width: pxToRem(60);
	}
	.mb__icon + h3:after {
		display: none;
	}
	.mb__icon + .content-grid__item-title {
		padding: 0 pxToRem(60) pxToRem(20) 0;
	}
	.content-grid__item-title {
		margin: 0;
		font-weight: 500;
		position: relative;
		font-size: pxToRem(21);
		padding: 0 0 pxToRem(20) 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 70px;
			height: 3px;
			background: $base-orange;
		}
	}
}

.content-grid__item-description {
	margin: 1.25rem 0 0rem;
}





/*
 *
 * Content GRID carousel
 *
 */
.module__grid-carousel {
	display: block;
	position: relative;
	padding: pxToRem(40) pxToRem(20) pxToRem(70);
	background: #E5EBF4;
	h2 {
		color: $base-blue;
		font-size: pxToRem(26);
	}
	.slick-arrow {
		border: none;
		display: block;
		width: 48px;
		height: 48px;
		background: $secondary-blue;
		color: #fff;
		z-index: 1;
		top: calc(60% - 61px);
	}
	.slick-next {
		right: 0;
		margin-top: 0;
	}
	.slick-prev {
		left: 0;
		margin-top: 0;
	}
}
.grid-carousel__container {
  margin: pxToRem(32) 0 0;
}
.grid-carousel__item {
	padding: pxToRem(55) pxToRem(38) pxToRem(0);
	position: relative;
	width: 100%;
	margin: 0;
	overflow: hidden;
	color: $base-white;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: auto; bottom: 0; left: 0; right: 0;
		z-index: 1;
		width: 100%;
		height: 100px;
		background: -moz-linear-gradient(top,  rgba(54,82,163,0) 0%, rgba(54,82,163,1) 50%, rgba(54,82,163,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(54,82,163,0) 0%,rgba(54,82,163,1) 50%,rgba(54,82,163,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(54,82,163,0) 0%,rgba(54,82,163,1) 50%,rgba(54,82,163,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	&:hover:after {
		height: 180px;
	}
	img {
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		width: 100%;
		height: auto;
		z-index: 0;
	}
	a {
		color: $base-white;
	}
	.place__bottom {
		width: 85%;
	}
}

@media only screen and (max-width: 470px) {
	.grid-carousel__item {
		height: 300px;
	}
	body .grid-carousel__item-title {
		padding: 250px 15px 0 15px;
	}
}

@media only screen and (min-width: 470px) and (max-width:600px) {
	.grid-carousel__item {
		height: 400px;
	}
	body .grid-carousel__item-title{
		padding: 340px 20px 0 20px;
	}
}

@media only screen and (min-width: 600px) and (max-width:700px) {
	.grid-carousel__item {
		height: 550px;
	}
	body .grid-carousel__item-title{
		padding: 500px 20px 0 20px;
	}
}
@media only screen and (min-width: 700px) and (max-width:800px) {
	.grid-carousel__item {
		height: 700px;
	}
	body .grid-carousel__item-title{
		padding: 640px 20px 0 20px;
	}
}

@media only screen and (min-width: 801px) and (max-width:1000px) {
	.grid-carousel__item {
		height: 400px;
	}
	body .grid-carousel__item-title{
		padding: 350px 20px 0 20px;
	}
}

@media only screen and (min-width: 1000px) and (max-width:1140px) {
	.grid-carousel__item {
		height: 450px;
	}
	body .grid-carousel__item-title{
		padding: 400px 20px 0 20px;
	}
}




body .grid-carousel__item-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  margin: 0;
  display: block;
  h3 {
  	font-size: pxToRem(21);
  	position: relative;
	  margin: 0 0 10px 0;
	  color: #fff;
	  padding-right: 1rem;
  	&:after {
	  	content: "+";
	  	color: $base-orange;
			position: absolute;
			right: 0;
			top: 0;
			font-size: 2rem;
			z-index: 2;
			width: 23px;
			height: 23px;
			line-height: 1rem;
		  text-align: center;
	  }
  }
  &:hover, &:focus {
  	text-decoration: none;
  	padding: 225px 20px 0 20px;
  	.grid-carousel__item-description {
  		display: block;
		}
		h3:after {
			display: none;
		}
		* {
			color: #fff;
			text-decoration: none;
		}
  }
}
.grid-carousel__item-description {
	margin: 0;
	position: relative;
  z-index: 3;
  display: none;
}
.place__bottom {
	position: absolute;
	bottom: 0;
	top: auto;
}
.place__top {
	position: absolute;
	top: auto;
}





/*
 *
 * Forms styling
 *
 */
// Global form styles
.sq-form,
.sq-form-section,
.sq-form fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
.sq-form {
  padding: 20px;
  background-color: $base-background;
}
abbr[title]{
	text-decoration: none;
}
.form-row-submit{
	margin: 10px 0 0 -5px;
}
.form-row-submit div.button + div.button {
	margin-left: 10px;
}
.form-row-submit.product-select {
    margin: 0 0 1rem 0;
}
.product-select label {
   margin: 0 .5rem 0 0;
}
.product-select input {
    width: 70%;
}
#product-listing .buy{
	display: flex;
	flex-direction: column;
}
#product-listing .price{
	margin: 1rem 0;
	align-self: center;
}

// Fields
.sq-form-field {
    border: none;
}

.sq-form input[type="text"],
.sq-form input[type="password"],
.sq-form input[type="number"],
.sq-form input[type="email"],
.sq-form input[type="tel"],
.sq-form textarea {
    width: 100%;
    border: none;
    padding: 1rem .75rem;

	&.readonly {
		background: none;
	}
}

.sq-form input,
.sq-form select,
.sq-form textarea {

	&:focus {
		border: 0;
		outline: none;
		box-shadow: inset 0 0 0 1px rgba(54, 82, 163, 1);
	}
}

// Disabled styling
option:disabled {
	color: #CCC;
}

// Date field
.sq-form-question-datetime {

	label {
		display: none;
	}
}

// Make the input the same size
.sq-form-question-datetime select {
	min-width: 4rem;
}

.sq-form textarea {
    border: none;
    padding: 0.5rem;
}

.sq-form .sq-form-question-answer ul {
	list-style: none;
	li {
		border: none;
		margin: 0;
		padding: 0;
	}
}

.sq-form-question-answer {
	.button.input-button {
		margin: 0;
	}
}


// Declaration
fieldset .sq-form-question-answer {

	ul {
		margin-left: 0;
		padding-left: 0;
	}

	li {
		margin: 0;
		padding: 0;
	}
}

.form-rows .sq-form div.sq-form-question {
	margin-bottom: 1rem !important;
}

// The next and prev buttons
.sq-form-buttons {
	margin-top: 1rem;
}


// Titles & Labels
.sq-form-question-title {
    margin:pxToRem(4) 0 pxToRem(4) 0;
    font-size: pxToRem(15);
    line-height: pxToRem(22);
    color: #5671b6;
	font-weight: 300;
}
.sq-form-question-title:first-letter {
    text-transform: capitalize;
}

.sq-form-question-answer label {
    color: #5671b6;
    font-weight: 300;
    margin: 0 0.5em 0 0;
    font-size: pxToRem(15);
    line-height: pxToRem(22);
}

.sq-form-section-title {
    display: block;
    padding: 0.5rem 0;
    font-size: pxToRem(22);
    font-weight: bold;
    border: none;
    text-decoration: none;
    color: #666;
}

legend.sq-form-question-title {
	margin-bottom: .5rem;
}
/*
legend.sq-form-question-title {
    font-size: 1rem;
    display: block;
    padding: 0.5rem 0;
    font-weight: bold;
    border: none;
    text-decoration: none;
    color: #666;
}
*/

// Lists
.sq-form-question-tickbox-list ul,
.sq-form-question-option-list ul {
    @extend %list-reset;
}

.sq-form .linebreak {
	clear: left;
}

.sq-form input[type="radio"],
.sq-form input[type="checkbox"],
.sq-form input[type="radio"] ~ label,
.sq-form input[type="checkbox"] ~ label {
    float: left;
}
.sq-form input[type="radio"],
.sq-form input[type="checkbox"] {
    margin: 0 pxToRem(6) 0 0;
}

.sq-form input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #dadfe5;
    color: $base-orange;
    box-shadow: none;
    padding: 4px;
    margin: 0 pxToRem(9) 0 0;
    height: 20px;
    width: 20px;
    display: block;
    float: left;
    position: relative;
    &:active, &:checked:active {
        background-color: #fff;
    }
    &:checked {
        background-color: #fff;
    }
    &:checked + span:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background: #fff;
        border-color: $base-black;
    }
    &:checked:after {
        /*content: '\2714';*/
        content: "";
        background: url('mysource_files/tick.svg') no-repeat 0 0;
        height: 10px;
        width: 13px;
        position: absolute;
        top: 4px;
        left: 3px;
        font-size: pxToRem(14);
    }
}
.sq-form input[type="radio"] {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #dadfe5;
    color: red;
    box-shadow: none;
    padding: 4px;
    margin: 0 pxToRem(9) 0 0;
    height: 20px;
    width: 20px;
    display: block;
    float: left;
    position: relative;
    @include sq-border-radius(50%);
    &:active, &:checked:active {
        background-color: #fafbfb;
    }
    &:checked {
        background-color: #fafbfb;
    }
    &:checked + span:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background: #eff1f4;
    }
    &:checked:after {
        content: "";
        height: 12px;
        width: 12px;
        position: absolute;
        top: 3px;
        left: 3px;
        @include sq-border-radius(50%);
        background: $base-orange;
    }
}

// Notes
.sq-form-question-note {
    @extend .sq-form-question-title;
    font-size: pxToRem(12);
    line-height: pxToRem(22);
}

.sq-form-question {
    @extend %clearfix;
}
.sq-form .clear-list ul li {
	display: block;
	clear: both;
	margin: 0 0 1rem 0;
	overflow: hidden;
}

// Date/Time
.sq-form .sq-form-question-datetime input[type="password"],
.sq-form .sq-form-question-datetime input[type="text"],
.sq-form .sq-form-question-datetime select {
    @extend %inline-block;
    width: auto;
	margin: 0;
	background-color: #fff;
}
.sq-form .sq-form-question-datetime label {
	@extend %inline-block;
    width: auto;
	margin: 0;
	background-color: transparent;
}

.sq-form .sq-form-question-datetime label.sq-form-error {
	display: none !important;
}

// Select boxes
.sq-form select {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-image: none;
  	-moz-appearance: none;
    -webkit-appearance: none;
    background-color: white;
	padding: 0.9375rem 0.3125rem 0.9375rem 0.3125rem;
}

select::-ms-expand {
    display: none !important;
}
.sq-form-question-select .sq-form-question-answer {
    margin: 0;
    padding: 0;
	position: relative;

    &:after {
    	content: "";
    	display: block;
    	position: absolute;

    	right: 1rem;
    	top: 0;
    	bottom: 0;
    	margin: auto;
    	width: 21px;
    	height: 15px;
		background: url(mysource_files/scroll-arrow.svg) no-repeat 0 0;
		pointer-events: none;
    }
}
.sq-form-question-select .sq-form-question-answer.maxdist:after{
	right:0;
}
// Errors
.sq-form-question-answer .sq-form-error,
.sq-form-required-field {
    color: red;
}
.sq-form-question-answer .sq-form-field.sq-form-error {
	border: 1px solid red;
}
.sq-form-question-answer .sq-form-error {
    clear: left;
}
.sq-form-question-error {
    color: red;
}
.button.input-button {
    padding: 0;
    margin: 1rem 0 1rem 0;
}
.button [type="submit"],
.button [type="button"] {
    border: none;
    background: none;
    padding: 1rem 1.5rem;
    margin: 0;
    color: $base-white;
}


.sq-form-question-answer table {
    td {
        border: none;
    }
    th {
        font-weight: bold;
        border: none;
    }
}

.form__label-toggle {
    position: relative;
    top: 26px;
    left: 10px;
    pointer-events: none;
}
.sq-form-question-datetime .form__label-toggle {
    position: initial;
    top: auto;
    pointer-events: all;
}
.form__label-focused {
    top: 0;
    pointer-events: all;
}


.mb__member-login {
	display: block;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	position: relative;
	z-index: 9;
	background: $base-background;
	&.active {
		height: 100%;
		overflow: visible;
		visibility: visible;
		opacity: 1;
		border-bottom: 3px solid #f8991d;
	}
	.row__inner {
		position: relative;
		@include sq-flex-box();
		flex-wrap: wrap;
	}
}
.mb__member-panel {
	width: 100%;
	padding: 1rem 2rem;
	&.mb__logo {
		width: 100%;
    text-align: center;
    padding: 1rem 1rem 1rem;
    margin: 0 0 1rem 0;
    border-bottom: 1px solid #919dbe;
	}
	form {
		padding: 0;
	}
}

.close-mb__member-login {
  	right: 0;
  	top: 1rem;
	position: absolute;
}



// The Testimonial carousel
.quote-carousel__section {
	display: block;
	position: relative;
	margin-bottom: 1rem;
	padding: pxToRem(40) 0 pxToRem(0);

	@media (max-width: 64rem) {
		margin: 0 15px;
	}

	h2 {
		color: $base-blue;
		font-size: pxToRem(26);
	}
}

// Layout
.quote-carousel__item {

	.quote-carousel__inner {
		display: flex;

		@media (max-width: 48rem) {
			flex-direction: column;
		}
	}

	.quote-left {
		width: 30%;
		min-height: 300px;
		background-color: #E6EBF4;

		display: flex;
		align-items: center;

		.quote-carousel__item-title {

			width: 85%;
			font-weight: 400;
			color: $base-black;

			small {
				display: block;
				font-weight: 300;
				margin: 10px 0 0 0;
			}
		}

		@media (max-width: 48rem) {
			width: 100%;
			min-height: 200px;
		}
	}

	.quote-right {
		width: 70%;
		min-height: 300px;
		background: white;

		display: flex;
		position: relative;
		align-items: center;

		&:before {
			content: "";

			width: 0;
			height: 0;
			margin: auto;
			z-index: 2;

			top: 0;
			bottom: 0;
			left: pxToRem(-50);
			position: absolute;
			border-style: solid;
			border-width: 0px 50px 50px 0;
			border-color: transparent #ffffff transparent transparent;
		}

		@media (max-width: 48rem) {
			width: 100%;
			min-height: 200px;

			.quote-carousel__item-description {
				margin-bottom: 2rem;
			}

			&:before {

				bottom: initial;
				top: pxToRem(-50);
				left: pxToRem(15);
				transform: rotate(180deg);
			}
		}
	}
}

.quote-carousel__pagination {
	z-index: 1;
	color: black;
	display: block;
	position: absolute;

	left: .85rem;
	bottom: 1rem;

	.slick-arrow {
		border: none;
		margin: 0;
		padding: 0;
		display: block;
		width: 26px;
		height: 26px;
		background: none;
		z-index: auto;
		position: absolute;
		left: 0;
		top: 0;
	}
	.slick-next {
		left: auto;
		right: 0;
	}
	.slick-dots {
		padding: 0 30px;
    margin: 0;
    li {
    	display: none;
    	margin: 0;
    }
    li.slick-active {
    	display: block;
    	position: relative;
    }
	}
}



.user__shopping-cart-toggler {
	// border-right: 1px solid #f0f0f0;
}

.user__personalisation_options {
	position: absolute;
	background: #fff;
	z-index: 1;
	padding: 1rem;
	display: none;
	right: -18px;
	top: 100%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

	&.active {
		display: block;
	}
  .user__personalisation_options-toggler {
  	color: $base-orange;
  	float: right;
  }
}
.user__personalisation_options-step {
	&[data-step] {
		display: none;
		&.active {
		  display: block;
		}
	}
}
.user__personalisation_options-audience,
.user__personalisation_options-postcode {
	h3 {
		margin: 0 0 1rem 0;
	}
	a {
		color: $base-blue;
	}
}
#user__personalisation_options-form {
	select, input, textarea {
		margin: 0 0 1rem 0;
		width: 100%;
	}

	input[type="submit"] {
		margin: 1rem 0 0;
	}
}
.user__personalisation a[class*="button__"] {
	width: 100%;
	margin: 0 0 1rem 0;
}

.members_area .user__members_area-options {
	position: absolute;
	width: 200px;
  background: #ebeef5;
  z-index: 9;
  padding: 1rem 1rem;
  display: none;
  right: 8px;
  &.active {
  	display: block;
  }
  ul {
  	list-style: none;
  	padding: 0;
  	margin: 2rem 0 0 0;
  	clear: both;
		list-style-image: url(mysource_files/listed-link-arrow.svg);
		list-style-position: inside;
		li {
			border-bottom: 1px solid #d7ddef;
			margin: 0 0 0.5rem 0;
			padding: 0.5rem 0;
		}
  	li {
  		a {
  			padding: 1rem 0;

			&.user__personalisation_options-toggler {
				display: inline;
			}
  		}
  	}
  }
  .user__members_area-toggler {
  	color: $base-orange;
  	float: right;
  }
}
.members_panel ul {
	padding: 0 0 0 1rem;
	list-style-image: url(mysource_files/listed-link-arrow.svg);
	li {
		position: relative;
		margin: 0 0 0.5rem 0;
	}
 }




#sq-message,
.sq-error {
	color: red;
  padding: 1rem 0;
}

.dom__shift {
	display: none;
}


/*
 *
 * MODULE: Members Information panel and buttons
 *
 */
.module__info-buttons {
	background: #E5EBF4;
	padding: 0 pxToRem(20);
	.info-panel__title,
	.button-panel__title {
		display: block;
		color: $base-blue;
		margin-bottom: pxToRem(25);
	}
}
.info-button__tiles {
	margin-left: 0 !important;
	padding-left: 0 !important;
	list-style: none !important;
	@include sq-flex-box();
	flex-wrap: wrap;
}
.info-button__tiles-item {
	width: 100%;
  	margin: 0 1px 1px 0;
	background: $base-white;
	display: flex;
	flex: 1;
	align-items: center;
	border-right: 1px solid #EEE;

	&:last-child {
		border-right: 0;
	}

	&.no-bg {
		background-color: transparent;
	}
}
a.info-button__tiles-link {
	display: block;
	color: $base-blue;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	padding: 1.5rem .75rem;
	font-weight: bold;
	width: 100%;
	min-height: 8.5rem;
	&:hover, &:focus {
		color: $base-orange;
	}
	.info-button__tiles-link-text {
		margin: 0;
	}
}
.info-button__tiles-icon {
	display: block;
	margin: 0 0 pxToRem(10) 0;
	img {
		height: 55px;
	}
}
.module__info-buttons {
	.info-panel {
		margin: 0 0 pxToRem(40);
		padding: 0;
		position: relative;
		p {
			line-height: 1.7;
		}

		a[class*="button__"]{
			margin-top: 1rem;
		}
	}
}

@media only screen and (max-width:1030px) {
	.module__info-buttons.row__global.pt-4.pb-4 {
		margin-top: 1rem;
	}
}


.main__content-wide div[id*="content_container"] {
	display: block;
	max-width: 71.25rem;
	margin: 0 auto;
	padding: 0;
}

div.pagination {
	display: block;
	clear: both;
	margin: 1rem 0;
	> a, > span {
		display: inline-block;
		margin: 0 2px 0 0;
		padding: 1rem;
	}
	.current {
		background: #fff;
	}
}

.news-media__listing {
	.card {
		margin: 0 0 1rem 0;
	}
}


.cpd-form {
	input[type="range"]{
		width: 100%;
	}
	.status-messsage {
		color: $base-green;
		font-weight: bold;
	}
}
/* Find a display Home styling  */
.display-home-details {
	display: block;
	.home-label {
		font-weight: bold;
	}
	.display-home-builder-details {
		a[class*="button"]{
			margin: 1rem 0;
		}
	}
}
.property {
	margin: 0 0 30px 0;
	.property-text {
		h3 {
			font-size: 1rem;
			margin-bottom: 0.5rem;
		}
	}
}
body ul.property-icons {
	margin: 0 0 1rem 0;
	padding: 0;
	background: $base-green;
	@include sq-flex-box();
	list-style: none !important;
	li {
		padding: 0.5rem 1rem;
		margin: 0 2px 0 0;
		.text {
			color: #fff;
			font-size: 1.1rem;
			display: block;
			margin: 0;
			float: right;
		}
	}
	img {
		display: inline-block;
		width: 30px;
		margin: 0 1rem 0 0;
	}
}
.display-home-carousel {
	display: block;
}
.display-home-carousel .slick-slide img {
	display: block;
	height: auto;
}
.display-homes-pagination {
	display: block;
    clear: both;
    width: 100%;
    overflow: hidden;
	ul {
		float: left;
		margin: 1rem 0;
		text-align: left;
		padding: 1rem 0;
		border-top: 1px solid $base-light-blue;
		border-bottom: 1px solid $base-light-blue;
		text-align: left;
		@include sq-flex-box();
		li {
			margin: 0 0.5rem 0 0;
			padding: 0;
		}
	}
}
.display-vallage-map #map {
	height: 700px;
	width: 100%;
	display: block;
}
.main__aside .display-vallage-map #map {
	height: 300px;
}

.maps__infowindow-content a.title {
    padding: 1rem 1rem 1rem 0;
	font-size: 1.4rem;
	display: inline-block;
	margin: 0 0 1rem 0;
	& + a {
		padding: 0.75rem 1rem 0.75rem;
		display: inline-block;
	}
}
.maps__infowindow-content {
	img {
		float: left;
		margin: 0 1rem 1rem 0;
	}
}

.aside__extra-content {
	margin: 1rem 0;
}
.aside-title {
	font-size: 1.2rem;
    width: 100%;
    display: block;
	margin: 0;
	a {
		display: block;
		width: 100%;
		padding-right: 0;
		text-align: left;
	}
}
.form-saving {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: $base-light-blue;
	text-align: center;
	opacity: 0.8;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

#notifications {
	position: relative;
	overflow: hidden;
}
.notifications__item {
	display: block;
	position: relative;
	clear: both;
	overflow: hidden;
	width: 100%;
	padding: 1rem;
	.notifications__close {
		float: right;
		padding: pxToRem(3) pxToRem(10);
		font-size: pxToRem(12);
	}
	 + .notifications__item {
		 border-top: 1px dashed #fff;
	 }
}
.notifications__content {
	display: block;
	font-size: pxToRem(14);
}

.member__tasks {
	.note {
		font-size: pxToRem(12);
		padding: 0;
		background: $base-orange;
		color: #fff;
		font-weight: bold;
		text-align: center;
		display: block;
		clear: both;
	}
}

.bb__posts {
	display: block;
	width: 100%;
	clear: both;
}
.bb__create-post {
	margin: 0 0 pxToRem(20) 0;
	width: 100%;
}
.bb__all-posts{
	width: 100%
}
.bb__post {
	display: block;
	position: relative;
	margin: 0 0 2rem 0;
	.bb__post-options {
		text-align: right;
		display: block;
		margin: 5px 0 0 0;
	}
	.bb__edit-post-link,
	.bb__remove-post-options {
		padding: 3px 10px;
		font-size: pxToRem(14);
	}
}
.bb__post-details {
	font-size: 12px;
    line-height: 18px;
    background: #fff;
    padding: 6px;
}
.bb__post-message {
	margin: 10px 0 5px;
}
.bb__post-type {
    font-size: 12px;
    background: $base-orange;
    padding: 2px 6px; margin: 0 5px 0 0;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
}
.bb__post-type-looking_for_work {
	background: $base-blue;
}
.bb__post-type-other {
	background: $secondary-blue;
}



/* Front End accordions Styles */
.mb_accordions-wrapper {
    margin-bottom: 15px;
	background-color: $base-background;
	padding: 15px;
}
.mb_accordions-wrapper--new {
	background-color: $base-background;
	padding: 15px;
}
.mb_accordion {
	margin-bottom: pxToRem(15);
	background: #fff;
	padding: pxToRem(15);
	.mb_accordions-title {
		position: relative;
		color: #666;
		margin: 0;
		font-weight: 400;
		padding-right: 2rem;
		&:after {
			content: "";
			display: block;
			position: absolute;
			right: 5px;
			top: 2px;
			width: 21px;
			height: 15px;
			background: url(mysource_files/scroll-arrow.svg) no-repeat 0 0;
		}
	}
	&.active .mb_accordions-title {
		margin: 0 0 15px 0;
		&:after {
			background: url(mysource_files/scroll-arrow-up.svg) no-repeat 0 0;
		}
	}

	.mb_accordions-title:hover,
	.mb_accordion.active .mb_accordions-title {
		cursor: pointer;
		text-decoration: none;
		overflow: visible;
		opacity: 1;
	}
	.mb_accordions-content {
		display: none;
	}
}

@media (min-width: 71rem) and (min-width: 992px) {
	body .show-wide-desktop {
	  display: block !important;
	  visibility: visible;
	}
}
@media (max-width: 71rem) and (min-width: 71rem) {
	body .hidden-wide-desktop {
	  display: none !important;
	  visibility: hidden;
	}
}

.promo-tile {
	display: block;
	padding: 3px;
	background: #fff;
	border: 1px solid #efefef;
	margin: 10px 0;
}
.promo-tile__description {
	padding: 10px;
}
.search-results__container .content-grid__item-title {
	font-size: 1.2rem;
	font-weight: normal;
	margin: 0;
	svg {
		display: none;
	}
}
.search-results__container  .content-grid__item-text {
	margin: 0;
}
.search-results__container > div {
	margin: 0 0 30px 0;
}
.search-results__facets span.badge {
	background: #f8991d;
	@include sq-border-radius(15px);
}
.ul-line-break {
	li {
		overflow: hidden;
	}
}


body.inside {
	.module__famb-search {
		position: relative;
		width: 100%;
		.famb__search {
			position: relative;
			width: 100%;
			margin: 0;
		}
		.sq-form-section-input {
			width: 100%;
			float: none;
			margin: 0 0 25px 0;
		}
	}
}
body .module__famb-search .famb-max-distance{ 
	margin: 0 0 40px 0;
	border-bottom: 2px solid #3652a3;
}
body .module__famb-search .famb-max-distance #maxdistselect{
	color: #3652a3;
	font-weight: lighter;
}

.famb-search__listing .famb-member-images__image-list{
	display: flex;
	flex-direction: column;
}
.famb-search__listing .famb-item__content .famb-member-images__image{
	margin: 2em 1em 1em 1em;
	max-width: 100%;
}
.main__content .famb-member-images__details img{
	max-height: 150px;
}
.famb-member-images__trash a{
	padding: .5em;
	color: #fff;
	display: block;
}
.famb-item__details {
	display: none;
}
.active .famb-item__details {
	display: block;
}
.famb-item__button--container {
	/*margin: 1rem 0 0 0;
	float: right;*/
	position: absolute;
	right: 1em;
	bottom: 1em;
}
.famb-search__listing .famb-item__content{
	margin: 1rem 0 3rem  .5rem;
}
body ul.famb-item__content-list {
	margin: 0;
	margin-top: 1rem;
		li {
			margin: 0 0 15px 0;
			word-break: break-all;
			hyphens: auto;
		}
}
.famb-search__listing .famb-item__title {
	margin: 0;
	position: relative;
	padding: 0 30px 0 0;
	img {
		position: absolute;
    right: 0;
    top: 0;
	}
}


// The Update profile images section
.member-image-builder {

	table {
		margin: 0;
	}

	td {
		padding: 0;
	}

	td.sq-limbo-field {
		padding: 0;
	}

	.sq-backend-smallprint {
		padding-bottom: 1.5rem;
		
	}
}

audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

iframe {
    width: 100%;

	&.custom-iframe {
		height: 400px;
	}
}

/*
 *
 * Module CTA Title
 *
 */
.module__cta-tile {
	.cta-tile {
		&__wrapper {
			display: flex;
			flex-direction: column;
			color: $base-white;
			padding: pxToRem(30);
			// justify-content: center;
		}

		&__link {
			display: block;
			width: 100%;
			background-color: $blue;
			color: $base-white;
			text-align: center;

			&:not(:last-child) {
				border-bottom: pxToRem(2) solid $base-white;
			}

			&:hover,
			&:focus {
				cursor: pointer;
				text-decoration: none;

				.cta-tile__title {
					text-decoration: underline;
				}
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			// align-items: center;
			padding: pxToRem(18) pxToRem(20);
		}

		&__icon {
			margin-bottom: pxToRem(20);

			svg {
				width: pxToRem(40);
				height: 100%;
			}
		}

		&__content {
			font-weight: bold;
		}

		&__title {
			font-size: pxToRem(20);
			margin-bottom: pxToRem(8);
		}
	}
}

#image-carousel-countainer {
	display: none;
}






