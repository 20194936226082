/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    background: $base-white;
    display: block;
    overflow-x: auto;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-white;
    color: $base-blue;
    padding: 1rem;
}
thead th {
    border-bottom: 2px solid $base-orange;
    padding: 1rem;
}
tbody th {
    font-weight: bold;
}
tbody tr:last-child td {
    border-bottom: none;
}
td {
    border-bottom: 1px solid #919dbe;
    padding: 1rem;
}

/* Buttons */
.button {
    @include sq-transition(background-color .3s ease);
    background-color: $base-blue;
    color: $base-white;
    display: inline-block;
    border: 0;
    padding: .5em 1em;
    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: darken($base-blue, 20%);
    }
}

.button--large {
    @include sq-font-size(1.5);
}

.button--small {
    @include sq-font-size(.8);
}

a.btn-default{
    font-weight:normal !important; 
    padding:0.5rem 0.75rem !important; 
    &:hover, &:focus{
        background-color: darken($base-blue, 12.5%) !important; 
    }
}

.mb__hRule {
    display: block;
    position: relative;
    margin: 3rem auto 4rem;
    width: 130px;
    height: 14px;
    background: url(mysource_files/quote-highlight.svg) no-repeat 0 0;
    clear: both;
}
/* Quotes */
blockquote {
    position: relative;
    padding: 0;
    margin: 2em 0;
    text-align: center;
    color: $base-blue;
    font-style: italic;
    &:before {
        content: "";
        display: block;
        position: relative;
        top: 0;
        left: 0;
        margin: 1rem auto;
        width: 130px;
        height: 14px;
        background: url(mysource_files/quote-highlight.svg) no-repeat 0 0;
    }
    footer {
        font-style: normal;
        font-weight: bold;
        color: $base-blue;
        a {
            color: $base-blue;
        }
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}

/* Login Popup */
#login-popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#login-popup .login-popup__underlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(000,000,000,0.5);
}

#login-popup .login-popup__content {
    position: absolute;
    background-color: white;
    z-index: 999;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
}

#login-popup .login-popup__content > p {
    color: #3652a3;
    text-align: center;
    padding: 20px 60px;
    font-weight: bold;
    max-width: 545px;
    margin: 0 auto;
}

#login-popup .login-popup__content ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    padding-bottom: 16px;
}

#login-popup .login-popup__content ul li {
    text-align: center;
    padding: 46px 0;
}

#login-popup .login-popup__content ul li a {
    font-weight: bold;
    padding: 6px 15px;
    text-transform: uppercase;
    min-width: 144px;
    display: inline-block;
}

#login-popup .login-popup__content ul li a:hover {
    text-decoration: none;
}

#login-popup .login-popup__content ul li:first-of-type {
    flex: 0 0 40%;
    text-align: center;
}

#login-popup .login-popup__content ul li:first-of-type a {
    color: white;
    background-color: #f8991d;
}

#login-popup .login-popup__content ul li:first-of-type a:hover {
    background-color: #fcaf4a;
}


#login-popup .login-popup__content ul li:last-of-type {
    flex: 0 0 60%;
    min-height: 120px;
    background: url('https://www.mbqld.com.au/__data/assets/image/0033/177756/varieties/w_320.png');
    background-size: 100%;
    background-position: 0;
    background-repeat: no-repeat;
}

#login-popup .login-popup__content ul li:last-of-type p  {
    color: white;
    font-weight: bold;
}

#login-popup .login-popup__content ul li:last-of-type a {
    background-color: white;
}

    #login-popup .login-popup__content ul li:last-of-type a:hover {
    background-color: #f3f3f3;
}

@media screen and (max-width: 1024px) {
    #login-popup .login-popup__content {
        width: 80%;
    }
}

@media screen and (max-width: 767px) {
    #login-popup .login-popup__content {
        width: 98%;
    }
}