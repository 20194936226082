/* Module: S */
body {
	z-index: 2;
}
body .hidden-mobile {
	display: inline-block !important;
	visibility: visible;
}
body .hidden-tablet {
    display: none !important;
    visibility: hidden;
}
.header {
	background: #fff;
}

.site-logo {
	// padding: 0 pxToRem(20);

	a {
		width: pxToRem(140);
	}
}

.info_bar-list-item {
	// padding-left: pxToRem(12);

 > a {
 	// width: auto;
 	// padding-left: pxToRem(8);
 	// padding-right: pxToRem(8);
 	@include sq-flex-box();
	align-items: center;
	justify-content: space-between;
 	span {
 		line-height: pxToRem(30);
 	}
 	span.mb_icon {
 		line-height: inherit;
 	}
 }
}
.mb_icon + span {
	margin-left: 10px;
}

.navigation__list {
 &.mobile_nav {
 	width: pxToRem(265);
 }
}
.navigation.active .mega-menu {
	padding: 20px;
	&.active {
		@include sq-flex-box();
		align-items: flex-start;
		/*align-items: flex-start | flex-end | center | baseline | stretch;*/
		align-content: flex-start;
    position: fixed;
    left: pxToRem(265);
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    background: $base-blue;
	}
	.mega-menu__list,
	.mega-menu__feature {
		width: 100%;
		margin: 0 auto;
	}
}
	




/*
 *
 * Global Footer styling
 *
*/
.footer {
	padding: pxToRem(40);
}
.mb__social-links {
	// display: inline-block;
	margin: pxToRem(30) 0;

	&:before {
		// content: "Connect with us";
		content: none;
		font-weight: bold;
		color: #fff;
		display: block;
		float: left;
		margin: 2px pxToRem(13) 0 0;
	}
}
.mb__member-links {
	width: auto;
	float: right;
	li {
		display: inline-block;
		margin: 0;
	}
}

.mb__legal-links {
	margin: pxToRem(30) 0 0 0;
	flex-direction: row;

	li {
		position: relative;

		&:last-child::after {
			display: none;
		}

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 12px;
			background: #fff;
			position: absolute;
			right: 0;
			top: 9px;
		}
	}

	&--contact {
		margin: 0 0 pxToRem(40) 0;
		font-size: pxToRem(18);

		li {
			padding: 0 12px;

			&:after {
			    font-weight: bold;
				width: 2px;
				height: 18px;
				top: 4px;
			}
		}
	}
}


/* 
 * 
 * MODULE: Page banner
 *
 */
.module__page-banner {
	min-height: 180px;
}
.page-banner__item {
    height: 265px;
}
.page-banner__details {
	// right: auto;
	// padding: pxToRem(20); 
}
.page-banner__description {
	display: block;
	margin: pxToRem(8) 0 pxToRem(16) 0;
}
.page-banner-has-image {
	.page-banner__details {	
		padding: pxToRem(40) pxToRem(10) pxToRem(40) pxToRem(20);
	}
}
a.page-banner__link {
	padding: pxToRem(12) pxToRem(24);
	font-size: pxToRem(16);
}

.page-banner__title {
	font-size: pxToRem(24);
	margin: 0;
}

/* 
 * 
 * MODULE: FAMB search form 
 *
 */
.famb__search {
	overflow: hidden;
	margin: pxToRem(20) pxToRem(30);
	.sq-form-section {
		float: left;
		width: 38%;
		margin: 0 pxToRem(35) 0 0;
	}
	.sq-submit-button {
		float: right;
	}
}
.famb__search-legend {
	font-size: pxToRem(20);
}
.famb-search__listing .famb-member-images__image-list{
	flex-direction: row;
}
.famb-search__listing .famb-item__content{
	margin: 1rem 8rem 0  .5rem;
}
body.inside .module__famb-search .famb-max-distance{
	width: 19%;
	float: left;
}

/* 
 * 
 * MODULE: Task tiles 
 *
 */
.task__tiles {
	// margin: 0 pxToRem(30) pxToRem(20);

}
.task__tiles-item {
	width: 33.33%;
	// margin: 0 1px 1px 0;
	// background: #fff;
}
.task__tiles-link {
	padding: pxToRem(20);
	font-size: pxToRem(18);
}
.task__tiles-icon {
	margin: 0 0 pxToRem(16) 0;
	height: 55px;
}
.task__tiles-title {
	font-size: pxToRem(18);
}


.module__why-a-member {
	width: 50%;
	float: left;
	.card {
		text-align: left;
	}
	.mb_icon {
		position: absolute;
		right: pxToRem(20);
		top: pxToRem(55);
		width: 130px;
	}
	.card-block {
		padding: 0 pxToRem(30) pxToRem(20);
	}
	.card-title {
		margin-top: 0;
	}
	.card-text {
		padding-right: 150px;
	}
}
.module__advert {
	width: 50%;
	float: left;
	background: #ccd2e5;
	padding: pxToRem(40) pxToRem(30);
	img {
		width: 100%;
	}
}


.mb__sponsors {
	
	h2 {
		// font-size: pxToRem(16);
		// text-align: left;
		// margin: 0 0 pxToRem(20) 0;
	}
	.mb__sponsors-link {
		// float: left;
		text-align: center;
		margin: 0 0 2rem 0;
		max-width: 25%;
		min-width: 33.33%;
		img {
			// width: auto;
    		max-height: 65px;
		}
	}
}


/*
 *
 * Content GRID flexbox
 *
 */
.content-grid__item {
	width: calc(50% - 20px);
	margin: 0 pxToRem(30) pxToRem(30) 0;

	&:nth-child(2n) {
		margin-right: 0;
	}
}
.mb__tabs-content {
	.mb__more-link {
		// right: 30px;
	}
	padding: 0 pxToRem(15) pxToRem(60);
}
.module__tabs {
	padding: 0;
}
.mb__tabs-list {
	margin: 0 pxToRem(30) pxToRem(27);
	li {
		display: inline-block;
		a {
			padding: pxToRem(16) pxToRem(48);
			font-size: pxToRem(18);
		}
	}
}

.mb__tabs {
	.slick-arrow {
		display: block;
	}

	.slick-next {
		right: 0;
		margin-top: 0;
	}
	.slick-prev {
		left: 0;
		margin-top: 0;
	}
}


.mb__sponsors {
	padding: pxToRem(40) pxToRem(30);
	overflow: hidden;

	&__wrapper {
		@include sq-flex-box();
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	h2 {
		// text-align: left;
		// flex-basis: 100%;
		margin: 0 0 pxToRem(40) 0;
	}
	.mb__sponsors-link {
		// float: none;
		// margin: 0 0 1rem 0;
		min-width: 33.33%;
		// text-align: left;
		flex-grow: 1;
	}
	.mb__sponsors-link.full-width {
		width: auto;
	}
}



.user__shopping-cart-toggler {
	// border-right: 1px solid #f0f0f0;
}
.module__page-banner.page-banner-has-image {
	min-height: 260px;
	.page-banner__details {
		position: absolute;
	}
	.page-banner__image {
		position: absolute;
	}
	.page-banner__item {
		height: 100%;
	}

	.page-banner__details {
		&--new {
			color: $base-white;

			&::before {
				background: transparent;
			}

			h1 {
				margin-bottom: 0;
			}

			.row {
				.col-xs-12 {
					.page-banner__buttons {
						flex-direction: row;
						a {
							&.button__white {
								border: 0;
							}
						}
					}
				}
			}	
		}
		
		&--dark {
			color: $base-black;
		}
	}
}
.page-banner-content h1 {
	font-size: pxToRem(31);
	font-weight: bold;
}

.module__page-banner.page-banner-content.page-banner-has-image .page-banner__details--new {
	bottom: pxToRem(-15);
	top: auto;
	position: absolute;
} 

/*
 *
 * MODULE: Members Information panel and buttons
 *
 */
.module__info-buttons {
	padding: 0 pxToRem(20);
}
.info-button__tiles-item {
	width: calc(33% - 1px);
}
.module__info-buttons {
	.info-panel {
		padding: 0 pxToRem(40) 0 0;
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 1px;
			height: 110%;
			background: #fff;
			right: 0;
			top: 0;
		}
	}
}

.notifications__content {
	display: block;
    float: left;
    width: 80%;
}
.task__tiles {
	// padding: pxToRem(20);
}
/*.task__tiles-item, 
.main__content ul li.task__tiles-item {
	width: 20%;
    margin: 0;
}*/


/* form addons */
.form-nester {
	margin: 2rem 0;
}
.form-rows {
	// additional styles
	overflow: hidden;
	.sq-form {
		div.sq-form-question {
			display: block;
			width: calc(100% - 10px);
			margin: 0 10px 0 0;
			float: left;
			clear: both;
		}
		> .sq-form-section ~ div.sq-form-question {
			float: none;
		}
		div.form-col-2 {
			width: calc(50% - 10px);
			margin: 0 10px 0 0;
			float: left;
			clear: none;
		}
		div.form-col-3 {
			width: calc(33.33% - 10px);
			margin: 0 10px 0 0;
			float: left;
			clear: none;
		}
		> div {
			overflow: hidden;
		}
		.form-clear-row {
			display: block;
			width: 100%;
			clear: both;
		}
	}
}


.module__grid-carousel .slick-arrow {
	top: calc(50% - 61px);
}

body.inside {
	.module__famb-search {
		position: relative;
		width: 100%;
		.famb__search {
			position: relative;
			width: 100%;
			overflow: visible;
		}
		.sq-form-section-input {
			width: 38%;
			float: left;
			margin: 0 15px 20px 0;
		}
		.disclaimer {
			clear: both;
		}
	}
}

.bb__create-post{
	width: auto;
	float: right;
}
.bb__all-posts{
	width: auto;
}

/*
 *
 * Module CTA Title
 *
 */
 .module__cta-tile {
	.cta-tile {
		&__wrapper {
			flex-direction: row;
			flex-wrap: wrap;
		}

		&__link {
			width: 50%;
			border-bottom: 0;

			&:not(:last-child) {
				border-bottom: 0;
			}

			&:nth-child(1),
			&:nth-child(3) {
				border-right: pxToRem(2) solid $base-white;
			}

			&:nth-child(1),
			&:nth-child(2) {
				border-bottom: pxToRem(2) solid $base-white;
			}
		}

		&__icon {

			svg {
				width: pxToRem(50);
			}
		}
	}
}

#image-carousel-countainer {
	display: block;
}

iframe {
	&.custom-iframe {
		height: 500px;
	}
}