/* Module: S */
.module__famb-search-main {
    .famb__search {
        padding: 40px 20px 20px 40px;
    }
    .famb__search--blocks {
        display: flex;
        .famb__search--block {
            flex: 1 0 33%;
            margin-right: 0 !important;
            padding: 0 40px 0 0;
        }
        .famb-max-distance {
            flex: 1 0 33%;
            padding: 0 40px 0 0;
        }
    }
    
}
