/* Module: S */
.famb--loader {
    display: block;
    width: 100%;
    clear: both;
    height: 100px;
    position: relative;

    .sk-folding-cube {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        margin: 30px auto 0;
        position: absolute;
        transform: rotateZ(45deg);
    }
    .sk-folding-cube .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }
    .sk-folding-cube .sk-cube:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000D35;
        -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
        animation: sk-foldCubeAngle 2.4s infinite linear both;
        -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
    .sk-folding-cube .sk-cube2 {
        -webkit-transform: scale(1.1) rotateZ(90deg);
        transform: scale(1.1) rotateZ(90deg);
    }
    .sk-folding-cube .sk-cube3 {
        -webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg);
    }
    .sk-folding-cube .sk-cube4 {
        -webkit-transform: scale(1.1) rotateZ(270deg);
        transform: scale(1.1) rotateZ(270deg);
    }
    .sk-folding-cube .sk-cube2:before {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .sk-folding-cube .sk-cube3:before {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    .sk-folding-cube .sk-cube4:before {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
    }
}
  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }


  
.module__famb-search-main {
    
    .famb__search-search-again {
        display: none;
    }
    &.famb__search-collapsed {
        .famb__search--collapse {
            display: none;
        }
        .famb__search-search-again {
            display: block;
        }
    }
    .famb__search {
        border: 1px solid #f8991e;
        padding: 40px 20px 20px 20px;
    }

    .famb__search .sq-form-section {
        border-bottom: none;
    }
    .sq-form-section {
        input[type="text"] {
            color: #000D35;
            padding: 0 0 0 10px;
            border-bottom: 2px solid #000D35;
            margin: 0 0 0 50px;
            width: calc(100% - 50px);
            font-size: 20px;
            ::placeholder {
                color: #000D35;
                opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
                color: #000D35;
            }
        }
        &:after {
            left: 0;
            right: auto;
        }
    }
    .sq-form-question-select {
        border-bottom: none;
        &.famb-max-distance {
            border-bottom: none;
        }
        .sq-form-question-answer {
            select {
                font-size: 20px;
                padding: 0;
                border: none;
                border-bottom: 2px solid #000D35;
                margin: 0 0 0 50px;
                width: calc(100% - 50px);
            }
            &:after {
                right: auto;
                left: 0;
            }
        }
    }
    .sq-submit-button {
        text-align: center;
        margin: 0 0 10px 0;
    }
    .disclaimer {
        text-align: center;
    }

    .famb__search-search-again {
        margin: 20px 0 0 0;
        text-align: right;
        cursor: pointer;
    }
}
.famb__search--note {
    display: block;
    font-size: 14px;
    color: #000;
    margin: 5px 0 0 50px;
}

.famb-item__header {
    position: relative;
}
.famb-item__recognition {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0;
}

.famb-item__milestone {
    flex: 0 0 auto;
    font-weight: bold;
    padding: 0;
    .famb-milestone {
        background: url('mysource_files/milestone_bg.png') no-repeat 0 0;
        background-size: contain;
        height: auto;
        width: 37px;
        color: #fff;
        text-align: center;
        padding: 20px 0 0 0;
        span {
            color: #fff;
            font-size: 20px;
            line-height: 20px;
            display: block;
            height: 20px;
            &.famb-milestone--year {
                font-size: 11px;
                text-transform: uppercase;
            }
        }

    }
    
}
.famb-item__milestone + .famb-item__award {
    padding: 0 0 0 20px;
}
.famb-item__award {
    flex: 0 0 auto;
    padding: 0;
    img {
        width: 32px;
    }
}


.home .famb__search {
    border: none;
    //padding: 40px 20px 20px 20px;

    .sq-form-section {
        border-bottom: none;
    }
    .sq-form-section {
        input[type="text"] {
            padding: 0 0 0 10px;
            border-bottom: 2px solid #000D35;
            margin: 0 0 0 50px;
            width: calc(100% - 50px);
            font-size: 20px;
            color: #000D35;
            ::placeholder {
                color: #000D35;
                opacity: 1; /* Firefox */
            }
            ::-ms-input-placeholder {
                color: #000D35;
            }
        }
        &:after {
            left: 0;
            right: auto;
        }
    }
    .sq-submit-button {
        text-align: center;
    }
}

body .famb__search .famb__search--block {
    &:before {
        content: "";
        display: block;
        width: 33px;
        height: 35px;
        position: absolute;
        right: auto;
        left: 0;
        top: 0;
    }
    &:after {
        content: "";
        display: block;
        width: 33px;
        height: 35px;
        position: absolute;
        right: auto;
        left: 0;
        top: 0;
    }

    &.famb__search--type {
        &:after {
            background: url(mysource_files/type-of-wrok-icon.svg) no-repeat 0 0;
        }
        &.famb__search--inactive {
            &:after {
                background: none;
            }
            &:before {
                background: url(mysource_files/type-of-wrok-icon-grey.svg) no-repeat 0 0;
            }
        }
    }

    &.famb__search--location {
        &:after {
            background: url(mysource_files/location-blip.svg) no-repeat 0 0;
        }
        &.famb__search--inactive {
            &:after {
                background: none;
            }
            &:before {
                background: url(mysource_files/location-blip-grey.svg) no-repeat 0 0;
            }
        }
    }

    &.famb__search--distance {
        &:after {
            background: url(mysource_files/MB_FAMB_RadiusIcon.svg) no-repeat 0 0;
        }
        &.famb__search--inactive {
            &:after {
                background: none;
            }
            &:before {
                background: url(mysource_files/MB_FAMB_RadiusIcon-grey.svg) no-repeat 0 0;
            }
        }
    }

}
#famb__search {
    background: #fff;
    .tt-cursor {
        color: #f8991e;
    }

    label.error {
        display: block;
        font-size: 14px;
        color: red;
        margin: 5px 0 0 50px;
    }
}

.famb-search__listing {
    .card-body {
        padding: pxToRem(20) pxToRem(20) pxToRem(80) pxToRem(20);
    }
}