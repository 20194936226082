/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// Load the variables 1st to override BS vars
// See bower_components/bootstrap/scss/_variables.scss
@import "variables.scss";
// Import Bootstrap
@import "../bower_components/bootstrap/scss/bootstrap.scss";
// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "functions.scss";
@import "mixins.scss";
@import "placeholders.scss";
/*
--------------------
Reset (normalize.css)
--------------------
*/

body {
    font-size: $base-font-scale + rem;
    font-family: $base-font-stack;
    font-weight: 300;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    color: $base-font-colour;
    background: transparent;
    &.mobile-menu-active {
        overflow: hidden;
    }
}

// == End box-sizing reset ==
/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";
/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    // text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    // text-shadow: none;
}

html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    line-height: 1.4;
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
//.content {
@import "content.scss";
//}
/* JIRA SDQLD-1183 Make content Images responsive */

.main img {
    height: auto;
}

/*
--------------------
Modules
--------------------
*/